import {
	NOTIFICATIONS_FETCH,
	NOTIFICATIONS_PUSH_INIT,
	NOTIFICATIONS_PUSH_EXIT,
	NOTIFICATIONS_ADD,
	NOTIFICATIONS_SUCCESS,
	NOTIFICATIONS_WAITING,
	NOTIFICATIONS_ERROR
} from "../actions.js";

const initialState = {
	notifications: [],
	notificationsPush: false,
	waiting: false,
	error: null
};

const reducer = (state = initialState, action) => {
	let array = state.notifications.map(element => ({ ...element }));
	switch (action.type) {
		case NOTIFICATIONS_FETCH:
			return {
				...state,
				notifications: action.payload.notifications.map(element => ({
					...element
				}))
			};
		case NOTIFICATIONS_PUSH_INIT:
			return {
				...state,
				notifications: array,
				notificationsPush: true
			};
		case NOTIFICATIONS_PUSH_EXIT:
			return {
				...state,
				notifications: array,
				notificationsPush: false
			};
		case NOTIFICATIONS_ADD: {
			array.push({ ...action.payload.notificationItem });
			return {
				...state,
				notifications: array
			};
		}
		case NOTIFICATIONS_SUCCESS:
			return {
				...state,
				waiting: false,
				error: null
			};
		case NOTIFICATIONS_WAITING:
			return {
				...state,
				waiting: true,
				error: null
			};
		case NOTIFICATIONS_ERROR:
			return {
				...state,
				error: action.payload.error,
				waiting: false
			};
		default:
			return state;
	}
};

export default reducer;
