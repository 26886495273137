import {
	STATISTICS_FETCH,
	STATISTICS_SUCCESS,
	STATISTICS_WAITING,
	STATISTICS_ERROR
} from "../actions.js";
import axios from "../../utils/functions/axios-instance";

import { statisticsGetRoute } from "../../utils/constants/constants-api";
import { connectionFilter } from "../../utils/functions/filtering-functions";

const statisticsSuccess = () => {
	return {
		type: STATISTICS_SUCCESS
	};
};

const statisticsInitiate = () => {
	return {
		type: STATISTICS_WAITING
	};
};

const statisticsError = error => {
	return {
		type: STATISTICS_ERROR,
		payload: {
			error: error
		}
	};
};

const statisticsFetch = data => {
	return {
		type: STATISTICS_FETCH,
		payload: {
			monthlySessions: data.monthlySessions,
			popularWorkouts: data.popularTrainings,
			sessionCount: data.sessionCount,
			sessionsToday: data.sessionsToday,
			sessionsRescheduledToday: data.sessionsRescheduledToday,
			sessionsFinishedToday: data.sessionsFinishedToday,
			userCount: data.userCount,
			profitLastMonth: data.profitLastMonth,
			profitTotal: data.profitTotal
		}
	};
};

export const statisticsFetchAll = () => {
	return (dispatch, getState) => {
		dispatch(statisticsInitiate());

		axios
			.getInstance()
			.get(statisticsGetRoute, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(statisticsFetch(response.data));
				dispatch(statisticsSuccess());
			})
			.catch(error => {
				dispatch(statisticsError(connectionFilter(error)));
			});
	};
};
