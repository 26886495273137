import React from "react";
import PropTypes from "prop-types";

import SettingsApplications from "@material-ui/icons/SettingsApplications";
import Dashboard from "@material-ui/icons/Dashboard";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { LocaleContext } from "../../context/localeContext";

function withNavigation(WrappedComponent) {
	return class extends React.Component {
		state = {
			value: "/"
		};

		onNavigate = (event, value) => {
			this.setState({
				value
			});
			this.props.history.push(value);
		};

		componentDidUpdate() {
			this.initiateByPath(this.props.history.location.pathname);
		}

		componentDidMount() {
			this.initiateByPath(this.props.history.location.pathname);
		}

		initiateByPath = pathname => {
			if (this.state.value !== pathname) {
				this.setState({
					value: pathname
				});
			}
		};

		renderNavigation = context => {
			if (!this.props.authenticated)
				return [
					{
						label:
							context.locale === "nl-NL"
								? "WAAROM FITSPRITE"
								: "WHY FITSPRITE",
						value: "/",
						icon: null,
						hyperlink: false
					},
					{
						label:
							context.locale === "nl-NL"
								? "VOEDING"
								: "NUTRITION",
						value: "https://www.5mict.com/fitsprite-nutrition/",
						icon: null,
						hyperlink: true
					},
					{
						label:
							context.locale === "nl-NL" ? "PRIJZEN" : "PRICING",
						value: "/pricing",
						icon: null,
						hyperlink: false
					},
					{
						label:
							context.locale === "nl-NL"
								? "ONDERSTEUNING"
								: "SUPPORT",
						value: "/support",
						icon: null,
						hyperlink: false
					},
					{
						label:
							context.locale === "nl-NL"
								? "PRIVACY POLICY"
								: "PRIVACY POLICY",
						value: "/privacy",
						icon: null,
						hyperlink: false
					},
					{
						label:
							context.locale === "nl-NL"
								? "PRODUCT DEMO"
								: "PRODUCT DEMO",
						value: "/demo",
						icon: null,
						hyperlink: false
					},
					{
						label:
							context.locale === "nl-NL"
								? "BEGIN"
								: "GET STARTED",
						value: "/auth",
						icon: null,
						hyperlink: false
					}
				];
			else
				return [
					{ label: "HOME", value: "/", icon: null, hyperlink: false },
					{ label: "CALENDAR", value: "/calendar", icon: null, hyperlink: false },
					{ label: "SCHEDULE", value: "/schedule", icon: null, hyperlink: false },
					{ label: "EQUIPMENT", value: "/equipment", icon: null, hyperlink: false },
					{ label: "VIDEOS", value: "/videos", icon: null, hyperlink: false },
					{ label: "WORKOUTS", value: "/media", icon: null, hyperlink: false },
					{
						label: "DASHBOARD",
						value: "/dashboard",
						icon: Dashboard,
						hyperlink: false
					},
					{
						label: "SETTINGS",
						value: "/settings",
						icon: SettingsApplications,
						hyperlink: false
					}
				];
		};

		render() {
			return (
				<LocaleContext.Consumer>
					{value => (
						<WrappedComponent
							links={this.renderNavigation(value)}
							locale={value.locale}
							localeChange={value.changeLanguage}
							navigate={this.onNavigate}
							active={this.state.value}
							color={
								this.state.value !== "/auth" &&
									!this.props.authenticated &&
									!this.props.initial &&
									!this.props.static
									? this.state.value === "/"
										? "transparent"
										: "dark"
									: "primary"
							}
							changeColor={
								!this.props.authenticated &&
									!this.props.initial &&
									!this.props.static &&
									this.state.value === "/"
									? {
										height: 400,
										color: "dark"
									}
									: undefined
							}
							{...this.props}
						/>
					)}
				</LocaleContext.Consumer>
			);
		}
	};
}

withNavigation.propTypes = {
	authenticated: PropTypes.bool.isRequired,
	history: PropTypes.object.isRequired
};

const mapStateToProps = state => {
	return {
		authenticated: state.auth.authenticated,
		initial: state.auth.initial
	};
};

export default compose(withRouter, connect(mapStateToProps), withNavigation);
