import {
	VIDEOS_FETCH,
	VIDEOS_ADD,
	VIDEOS_REMOVE,
	VIDEOS_EDIT,
	VIDEOS_SELECT,
	VIDEOS_SUCCESS,
	VIDEOS_WAITING,
	VIDEOS_ERROR,
	VIDEOS_UPDATE_UPLOAD_PERCENTAGE
} from "../actions.js";

const initialState = {
	videos: [],
	selected: null,
	waiting: false,
	error: null,
	percentageUploaded: 0
};

const reducer = (state = initialState, action) => {
	let array = state.videos.map(element => ({ ...element }));
	switch (action.type) {
		case VIDEOS_FETCH:
			return {
				...state,
				videos: action.payload.videos.map(element => ({ ...element }))
			};
		case VIDEOS_ADD:
			array.push({ ...action.payload.videoItem });
			return {
				...state,
				videos: array
			};
		case VIDEOS_REMOVE:
			return {
				...state,
				videos: array.filter(
					element => element.id !== action.payload.id
				)
			};
		case VIDEOS_EDIT:
			return {
				...state,
				videos: state.videos.map(element =>
					element.id === action.payload.videoItem.id
						? { ...action.payload.videoItem }
						: { ...element }
				),
				selected: {
					...action.payload.videoItem
				}
			};
		case VIDEOS_SELECT:
			return {
				...state,
				selected:
					action.payload.id !== null
						? {
								...state.videos.find(
									element => element.id === action.payload.id
								)
						  }
						: null
			};
		case VIDEOS_UPDATE_UPLOAD_PERCENTAGE:
			return {
				...state,
				percentageUploaded: action.payload.percentage
			};
		case VIDEOS_SUCCESS:
			return {
				...state,
				waiting: false,
				error: null
			};
		case VIDEOS_WAITING:
			return {
				...state,
				waiting: true,
				error: null
			};
		case VIDEOS_ERROR:
			return {
				...state,
				error: action.payload.error,
				waiting: false
			};
		default:
			return state;
	}
};

export default reducer;
