import {
	USERS_FETCH,
	USERS_SELECT,
	USERS_WAITING,
	USERS_SUCCESS,
	USERS_ERROR
} from "../actions.js";
import axios from "../../utils/functions/axios-instance";

import {
	usersGetRoute,
	usersGetUserRoute
} from "../../utils/constants/constants-api";
import { connectionFilter } from "../../utils/functions/filtering-functions";

const usersSuccess = () => {
	return {
		type: USERS_SUCCESS
	};
};

const usersInitiate = () => {
	return {
		type: USERS_WAITING
	};
};

const usersError = error => {
	return {
		type: USERS_ERROR,
		payload: {
			error: error
		}
	};
};

const usersFetch = users => {
	return {
		type: USERS_FETCH,
		payload: {
			users: users
		}
	};
};

const usersSelectItem = user => {
	return {
		type: USERS_SELECT,
		payload: {
			user: user
		}
	};
};

export const usersFetchAll = () => {
	return (dispatch, getState) => {
		dispatch(usersInitiate());

		axios
			.getInstance()
			.get(usersGetRoute, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(usersFetch(response.data));
				dispatch(usersSuccess());
			})
			.catch(error => {
				dispatch(usersError(connectionFilter(error)));
			});
	};
};

export const usersSelectUser = id => {
	return (dispatch, getState) => {
		dispatch(usersInitiate());

		if (id) {
			axios
				.getInstance()
				.get(usersGetUserRoute, {
					params: { id: id },
					headers: {
						Authorization: getState().user.token
					}
				})
				.then(response => {
					dispatch(usersSelectItem(response.data));
					dispatch(usersSuccess());
				})
				.catch(error => {
					dispatch(usersError(connectionFilter(error)));
				});
		} else {
			dispatch(usersSelectItem(null));
			dispatch(usersSuccess());
		}
	};
};
