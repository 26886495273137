import {
	SESSIONS_FETCH,
	SESSIONS_FETCH_HANDLED,
	SESSIONS_FETCH_WEEKLY,
	SESSIONS_FETCH_USER,
	SESSIONS_RESCHEDULE,
	SESSIONS_ACCEPT,
	SESSIONS_REJECT,
	SESSIONS_CANCEL,
	SESSIONS_SELECT,
	SESSIONS_SUCCESS,
	SESSIONS_WAITING,
	SESSIONS_ERROR,
	SESSIONS_FETCH_WEEKLY_HISTORY
} from "../actions.js";

const initialState = {
	sessions: [],
	handled: [],
	self: [],
	weekly: {},
	weeklyHistory: {},
	selected: null,
	waiting: false,
	error: null
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SESSIONS_FETCH:
			return {
				...state,
				sessions: action.payload.sessions.map(element => ({
					...element
				}))
			};
		case SESSIONS_FETCH_HANDLED:
			return {
				...state,
				handled: action.payload.sessions.map(element => ({
					...element
				}))
			};
		case SESSIONS_FETCH_WEEKLY:
			return {
				...state,
				weekly: JSON.parse(JSON.stringify(action.payload.sessions))
			};
		case SESSIONS_FETCH_WEEKLY_HISTORY:
			return {
				...state,
				weeklyHistory: JSON.parse(
					JSON.stringify(action.payload.sessions)
				)
			};
		case SESSIONS_FETCH_USER:
			return {
				...state,
				self: action.payload.sessions.map(element => ({ ...element }))
			};
		case SESSIONS_ACCEPT:
			return {
				...state,
				sessions: state.sessions.map(element =>
					element.id !== action.payload.id
						? { ...element }
						: { ...element, status: "ACCEPTED" }
				)
			};
		case SESSIONS_REJECT:
			return {
				...state,
				sessions: state.sessions.map(element =>
					element.id !== action.payload.id
						? { ...element }
						: { ...element, status: "REJECTED" }
				)
			};
		case SESSIONS_RESCHEDULE: {
			return {
				...state,
				weekly: { ...state.weekly }
			};
		}
		case SESSIONS_SELECT: {
			return {
				...state,
				selected:
					action.payload.selected !== null
						? {
								...action.payload.selected
						  }
						: null
			};
		}
		case SESSIONS_CANCEL:
			return {
				...state,
				waiting: false,
				error: null
			};
		case SESSIONS_SUCCESS:
			return {
				...state,
				waiting: false,
				error: null
			};
		case SESSIONS_WAITING:
			return {
				...state,
				waiting: true,
				error: null
			};
		case SESSIONS_ERROR:
			return {
				...state,
				error: action.payload.error,
				waiting: false
			};
		default:
			return state;
	}
};

export default reducer;
