import {
	internalMaintenanceEnable,
	internalMaintenanceDisable
} from "../../store/actions/index";
import { authLogout } from "../../store/actions/index";

export default class maintenanceService {
	static instance = null;

	constructor(store) {
		maintenanceService.instance = {
			redux: store
		};
	}

	static getInstance() {
		if (maintenanceService.instance == null) {
			maintenanceService.instance = {
				redux: null
			};
		}

		return maintenanceService.instance;
	}

	static enable() {
		maintenanceService.instance.redux.dispatch(authLogout());
		maintenanceService.instance.redux.dispatch(internalMaintenanceEnable());
	}

	static disable() {
		maintenanceService.instance.redux.dispatch(
			internalMaintenanceDisable()
		);
	}
}
