import {
	USERS_FETCH,
	USERS_SELECT,
	USERS_WAITING,
	USERS_SUCCESS,
	USERS_ERROR
} from "../actions.js";

const initialState = {
	users: [],
	selected: null,
	waiting: false,
	error: null
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case USERS_FETCH:
			return {
				...state,
				users: action.payload.users.map(element => ({ ...element }))
			};
		case USERS_SELECT:
			return {
				...state,
				selected:
					action.payload.user !== null
						? {
								id: action.payload.user.id,
								name: action.payload.user.name,
								email: action.payload.user.email,
								phone: action.payload.user.phone,
								source: action.payload.user.source,
								sessions: action.payload.user.sessions,
								sessionsList: action.payload.user.session_list
						  }
						: null
			};
		case USERS_SUCCESS:
			return {
				...state,
				waiting: false,
				error: null
			};
		case USERS_WAITING:
			return {
				...state,
				waiting: true,
				error: null
			};
		case USERS_ERROR:
			return {
				...state,
				error: action.payload.error,
				waiting: false
			};
		default:
			return state;
	}
};

export default reducer;
