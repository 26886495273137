export const INTERNAL_NOTIFICATIONS_ADD = "INTERNAL_NOTIFICATIONS_ADD";
export const INTERNAL_NOTIFICATIONS_REMOVE = "INTERNAL_NOTIFICATIONS_REMOVE";
export const INTERNAL_MAINTENANCE_ENABLE = "INTERNAL_MAINTENANCE_ENABLE";
export const INTERNAL_MAINTENANCE_DISABLE = "INTERNAL_MAINTENANCE_DISABLE";

export const AUTH_TRY = "AUTH_TRY";
export const AUTH_INITIATE = "AUTH_INITIATE";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_REDIRECT = "AUTH_REDIRECT";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_ERROR = "AUTH_ERROR";

export const USER_FETCH_DATA = "USER_FETCH_DATA";
export const USER_ADD_NOTIFICATION_ID = "USER_ADD_NOTIFICATION_ID";
export const USER_CLEAR_CREDENTIALS = "USER_CLEAR_CREDENTIALS";
export const USER_UPDATE_TOKEN = "USER_UPDATE_TOKEN";
export const USER_WAITING = "USER_WAITING";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_ERROR = "USER_ERROR";

export const USERS_FETCH = "USERS_FETCH";
export const USERS_SELECT = "USERS_SELECT";
export const USERS_SUCCESS = "USERS_SUCCESS";
export const USERS_WAITING = "USERS_WAITING";
export const USERS_ERROR = "USERS_ERROR";

export const SESSIONS_FETCH = "SESSIONS_FETCH";
export const SESSIONS_FETCH_HANDLED = "SESSIONS_FETCH_HANDLED";
export const SESSIONS_FETCH_WEEKLY = "SESSIONS_FETCH_WEEKLY";
export const SESSIONS_FETCH_WEEKLY_HISTORY = "SESSIONS_FETCH_WEEKLY_HISTORY";
export const SESSIONS_FETCH_USER = "SESSIONS_FETCH_USER";
export const SESSIONS_RESCHEDULE = "SESSIONS_RESCHEDULE";
export const SESSIONS_CANCEL = "SESSIONS_CANCEL";
export const SESSIONS_ACCEPT = "SESSIONS_ACCEPT";
export const SESSIONS_REJECT = "SESSIONS_REJECT";
export const SESSIONS_SELECT = "SESSIONS_SELECT";
export const SESSIONS_SUCCESS = "SESSIONS_SUCCESS";
export const SESSIONS_WAITING = "SESSIONS_WAITING";
export const SESSIONS_ERROR = "SESSIONS_ERROR";

export const EQUIPMENT_FETCH = "EQUIPMENT_FETCH";
export const EQUIPMENT_ADD = "EQUIPMENT_ADD";
export const EQUIPMENT_REMOVE = "EQUIPMENT_REMOVE";
export const EQUIPMENT_EDIT = "EQUIPMENT_EDIT";
export const EQUIPMENT_SELECT = "EQUIPMENT_SELECT";
export const EQUIPMENT_SUCCESS = "EQUIPMENT_SUCCESS";
export const EQUIPMENT_WAITING = "EQUIPMENT_WAITING";
export const EQUIPMENT_ERROR = "EQUIPMENT_ERROR";

export const WORKOUTS_FETCH = "WORKOUTS_FETCH";
export const WORKOUTS_ADD = "WORKOUTS_ADD";
export const WORKOUTS_REMOVE = "WORKOUTS_REMOVE";
export const WORKOUTS_EDIT = "WORKOUTS_EDIT";
export const WORKOUTS_SELECT = "WORKOUTS_SELECT";
export const WORKOUTS_SUCCESS = "WORKOUTS_SUCCESS";
export const WORKOUTS_WAITING = "WORKOUTS_WAITING";
export const WORKOUTS_ERROR = "WORKOUTS_ERROR";

export const VIDEOS_FETCH = "VIDEOS_FETCH";
export const VIDEOS_ADD = "VIDEOS_ADD";
export const VIDEOS_REMOVE = "VIDEOS_REMOVE";
export const VIDEOS_EDIT = "VIDEOS_EDIT";
export const VIDEOS_SELECT = "VIDEOS_SELECT";
export const VIDEOS_SUCCESS = "VIDEOS_SUCCESS";
export const VIDEOS_WAITING = "VIDEOS_WAITING";
export const VIDEOS_ERROR = "VIDEOS_ERROR";
export const VIDEOS_UPDATE_UPLOAD_PERCENTAGE =
	"VIDEOS_UPDATE_UPLOAD_PERCENTAGE";

export const SCHEDULE_FETCH_WORKING_DAYS = "SCHEDULE_FETCH_WORKING_DAYS";
export const SCHEDULE_FETCH_EXCEPTIONS = "SCHEDULE_FETCH_EXCEPTIONS";
export const SCHEDULE_FETCH_RECURRING_EXCEPTIONS =
	"SCHEDULE_FETCH_RECURRING_EXCEPTIONS";
export const SCHEDULE_FETCH_HOLIDAYS = "SCHEDULE_FETCH_HOLIDAYS";
export const SCHEDULE_SET_WORKING_DAYS = "SCHEDULE_SET_WORKING_DAYS";
export const SCHEDULE_SET_EXCEPTIONS = "SCHEDULE_SET_EXCEPTIONS";
export const SCHEDULE_SET_RECURRING_EXCEPTIONS =
	"SCHEDULE_SET_RECURRING_EXCEPTIONS";
export const SCHEDULE_SET_HOLIDAYS = "SCHEDULE_SET_HOLIDAYS";
export const SCHEDULE_CHANGE_MONTH = "SCHEDULE_CHANGE_MONTH";
export const SCHEDULE_REMOVE_RECURRING_EXCEPTION =
	"SCHEDULE_DELETE_RECURRING_EXCEPTION";
export const SCHEDULE_REMOVE_EXCEPTION = "SCHEDULE_REMOVE_EXCEPTION";
export const SCHEDULE_REMOVE_HOLIDAY = "SCHEDULE_REMOVE_HOLIDAY";
export const SCHEDULE_SUCCESS = "SCHEDULE_SUCCESS";
export const SCHEDULE_WAITING = "SCHEDULE_WAITING";
export const SCHEDULE_ERROR = "SCHEDULE_ERROR";

export const NOTIFICATIONS_FETCH = "NOTIFICATIONS_FETCH";
export const NOTIFICATIONS_PUSH_INIT = "NOTIFICATIONS_PUSH_INIT";
export const NOTIFICATIONS_PUSH_EXIT = "NOTIFICATIONS_PUSH_EXIT";
export const NOTIFICATIONS_ADD = "NOTIFICATIONS_ADD";
export const NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS";
export const NOTIFICATIONS_WAITING = "NOTIFICATIONS_WAITING";
export const NOTIFICATIONS_ERROR = "NOTIFICATIONS_ERROR";

export const STATISTICS_FETCH = "STATISTICS_FETCH";
export const STATISTICS_SUCCESS = "STATISTICS_SUCCESS";
export const STATISTICS_WAITING = "STATISTICS_WAITING";
export const STATISTICS_ERROR = "STATISTICS_ERROR";

export const AVAILABILITY_FETCH = "AVAILABILITY_FETCH";
export const AVAILABILITY_SUCCESS = "AVAILABILITY_SUCCESS";
export const AVAILABILITY_WAITING = "AVAILABILITY_WAITING";
export const AVAILABILITY_ERROR = "AVAILABILITY_ERROR";

export const TIMELINE_FETCH = "TIMELINE_FETCH";
export const TIMELINE_SUCCESS = "TIMELINE_SUCCESS";
export const TIMELINE_WAITING = "TIMELINE_WAITING";
export const TIMELINE_ERROR = "TIMELINE_ERROR";

export const SETTINGS_WAITING = "SETTINGS_INITIATE";
export const SETTINGS_FETCH = "SETTINGS_FETCH";
export const SETTINGS_SUCCESS = "SETTINGS_SUCCESS";
export const SETTINGS_ERROR = "SETTINGS_ERROR";
export const SETTINGS_CHANGE_EMAIL = "SETTINGS_CHANGE_EMAIL";
export const SETTINGS_CHANGE_PASSWORD = "SETTINGS_CHANGE_PASSWORD";
export const SETTINGS_CHANGE_COMPANY_NAME = "SETTINGS_CHANGE_COMPANY_NAME";
export const SETTINGS_CHANGE_COMPANY_ADDRESS =
	"SETTINGS_CHANGE_COMPANY_ADDRESS";
export const SETTINGS_CHANGE_COMPANY_PHOTO = "SETTINGS_CHANGE_COMPANY_PHOTO";
export const SETTINGS_CHANGE_NOTIFICATIONS_EXCEPTIONS =
	"SETTINGS_CHANGE_NOTIFICATIONS_EXCEPTIONS";
export const SETTINGS_ADD_PACK = "SETTINGS_ADD_PACK";
export const SETTINGS_ADD_MEMBERSHIP = "SETTINGS_ADD_MEMBERSHIP";
export const SETTINGS_ADD_PROMOTION = "SETTINGS_ADD_PROMOTION";
export const SETTINGS_REMOVE_PROMOTION = "SETTINGS_REMOVE_PROMOTION";
export const SETTINGS_ACTIVATE_PROMOTION = "SETTINGS_ACTIVATE_PROMOTION";
export const SETTINGS_DISABLE_PROMOTION = "SETTINGS_DISABLE_PROMOTION";
export const SETTINGS_ENABLE_PACK = "SETTINGS_ENABLE_PACK";
export const SETTINGS_DISABLE_PACK = "SETTINGS_DISABLE_PACK";
export const SETTINGS_ENABLE_MEMBERSHIP = "SETTINGS_ENABLE_MEMBERSHIP";
export const SETTINGS_DISABLE_MEMBERSHIP = "SETTINGS_DISABLE_MEMBERSHIP";

export const PAYMENTS_FETCH = "PAYMENTS_FETCH";
export const PAYMENTS_FETCH_PENDING = "PAYMENTS_FETCH_PENDING";
export const PAYMENTS_FETCH_LOCAL_PENDING = "PAYMENTS_FETCH_LOCAL_PENDING";
export const PAYMENTS_FETCH_MONTHLY_REPORT = "PAYMENTS_FETCH_MONTHLY_REPORT";
export const PAYMENTS_CHANGE_STATUS = "PAYMENTS_CHANGE_STATUS";
export const PAYMENTS_SELECT = "PAYMENTS_SELECT";
export const PAYMENTS_EDIT = "PAYMENTS_EDIT";
export const PAYMENTS_SUCCESS = "PAYMENTS_SUCCESS";
export const PAYMENTS_WAITING = "PAYMENTS_WAITING";
export const PAYMENTS_ERROR = "PAYMENTS_ERROR";
