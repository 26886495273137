export const colors = {
	calendarHighlightColor: "#f1f3f6"
};

export const statisticsColors = [
	"#aedad3",
	"#d4e1ac",
	"#ffe082",
	"#ffcc80",
	"#d1e19f",
	"#fff59d",
	"#bcaaa4",
	"#ffab91",
	"#ef9a9a",
	"#d5ee8a",
	"#f48fb1",
	"#ce93d8",
	"#90caf9",
	"#9fa8da",
	"#80deea",
	"#81d4fa",
	"#80cbc4"
];

export const calendarAvailabilityColors = {
	highAvailability: "rgba(78, 186, 111, 0.55)",
	mediumAvailability: "rgba(222, 196, 25, 0.55)",
	lowAvailability: "rgba(222, 113, 42, 0.55)",
	noAvailability: "rgba(222, 76, 76, 0.55)",
	inactiveDay: "rgba(217, 224, 230, 0.55)"
};

export const calendarBaseColors = {
	blue: "rgba(120, 210, 240, 0.7)",
	yellow: "rgba(255, 219, 121, 0.7)",
	red: "rgba(255, 100, 119, 0.7)"
};
