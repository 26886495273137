import {
	AVAILABILITY_FETCH,
	AVAILABILITY_WAITING,
	AVAILABILITY_SUCCESS,
	AVAILABILITY_ERROR
} from "../actions.js";

import { bridgeAgendaForSchedule } from "../../utils/functions/bridge-agenda";

const initialState = {
	markedDates: {},
	agendaMarkedDates: {},
	waiting: false,
	error: null
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case AVAILABILITY_FETCH:
			return {
				...state,
				markedDates: { ...action.payload.markedDates },
				agendaMarkedDates: bridgeAgendaForSchedule(
					action.payload.markedDates
				)
			};
		case AVAILABILITY_SUCCESS:
			return {
				...state,
				waiting: false,
				error: null
			};
		case AVAILABILITY_WAITING:
			return {
				...state,
				waiting: true,
				error: null
			};
		case AVAILABILITY_ERROR:
			return {
				...state,
				error: action.payload.error,
				waiting: false
			};
		default:
			return state;
	}
};

export default reducer;
