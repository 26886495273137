import {
	WORKOUTS_FETCH,
	WORKOUTS_ADD,
	WORKOUTS_REMOVE,
	WORKOUTS_EDIT,
	WORKOUTS_SELECT,
	WORKOUTS_SUCCESS,
	WORKOUTS_WAITING,
	WORKOUTS_ERROR
} from "../actions.js";

const initialState = {
	workouts: [],
	selected: null,
	waiting: false,
	error: null
};

const reducer = (state = initialState, action) => {
	let array = state.workouts.map(element => ({ ...element }));
	switch (action.type) {
		case WORKOUTS_FETCH:
			return {
				...state,
				workouts: action.payload.workouts.map(element => ({
					...element
				}))
			};
		case WORKOUTS_ADD:
			array.push({ ...action.payload.workoutItem });
			return {
				...state,
				workouts: array
			};
		case WORKOUTS_REMOVE:
			return {
				...state,
				workouts: array.filter(
					element => element.id !== action.payload.id
				)
			};
		case WORKOUTS_EDIT:
			return {
				...state,
				workouts: state.workouts.map(element =>
					element.id === action.payload.workoutItem.id
						? { ...action.payload.workoutItem }
						: { ...element }
				),
				selected: {
					...action.payload.workoutItem
				}
			};
		case WORKOUTS_SELECT:
			return {
				...state,
				selected:
					action.payload.id !== null
						? {
								...state.workouts.find(
									element => element.id === action.payload.id
								),
								video: {
									...action.payload.video
								}
						  }
						: null
			};
		case WORKOUTS_SUCCESS:
			return {
				...state,
				waiting: false,
				error: null
			};
		case WORKOUTS_WAITING:
			return {
				...state,
				waiting: true,
				error: null
			};
		case WORKOUTS_ERROR:
			return {
				...state,
				error: action.payload.error,
				waiting: false
			};
		default:
			return state;
	}
};

export default reducer;
