import {
	VIDEOS_FETCH,
	VIDEOS_ADD,
	VIDEOS_REMOVE,
	VIDEOS_EDIT,
	VIDEOS_SELECT,
	VIDEOS_SUCCESS,
	VIDEOS_WAITING,
	VIDEOS_ERROR,
	VIDEOS_UPDATE_UPLOAD_PERCENTAGE
} from "../actions.js";
import axios from "../../utils/functions/axios-instance";

import {
	videosCreateVideoRoute,
	videosGetVideosRoute,
	videosUpdateVideoRoute,
	videosDeleteVideoRoute
} from "../../utils/constants/constants-api";
import { connectionFilter } from "../../utils/functions/filtering-functions";

const videosSuccess = () => {
	return {
		type: VIDEOS_SUCCESS
	};
};

const videosInitiate = () => {
	return {
		type: VIDEOS_WAITING
	};
};

const videosError = error => {
	return {
		type: VIDEOS_ERROR,
		payload: {
			error: error
		}
	};
};

const videosFetch = videos => {
	return {
		type: VIDEOS_FETCH,
		payload: {
			videos: videos
		}
	};
};

const videosAdd = videoItem => {
	return {
		type: VIDEOS_ADD,
		payload: {
			videoItem: videoItem
		}
	};
};

const videosRemove = id => {
	return {
		type: VIDEOS_REMOVE,
		payload: {
			id: id
		}
	};
};

const videosEdit = videoItem => {
	return {
		type: VIDEOS_EDIT,
		payload: {
			videoItem: videoItem
		}
	};
};

export const videosSelectItem = id => {
	return {
		type: VIDEOS_SELECT,
		payload: {
			id: id
		}
	};
};

export const videosUpdateUploadPercentage = percentage => {
	return {
		type: VIDEOS_UPDATE_UPLOAD_PERCENTAGE,
		payload: {
			percentage: percentage
		}
	};
};

export const videosFetchAll = () => {
	return (dispatch, getState) => {
		dispatch(videosInitiate());

		axios
			.getInstance()
			.get(videosGetVideosRoute, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(videosFetch(response.data));
				dispatch(videosSuccess());
			})
			.catch(error => {
				dispatch(videosError(connectionFilter(error)));
			});
	};
};

export const videosAddItem = (title, description, source, thumbnail, steps) => {
	return (dispatch, getState) => {
		dispatch(videosInitiate());
		dispatch(videosUpdateUploadPercentage(0));

		const payload = new FormData();
		payload.append("title", title);
		payload.append("description", description);
		if (source) payload.append("source", source, source.name);
		if (thumbnail) payload.append("thumbnail", thumbnail, thumbnail.name);
		payload.append("steps", JSON.stringify(steps));

		axios
			.getInstance()
			.post(videosCreateVideoRoute, payload, {
				onUploadProgress: progressEvent => {
					let percentage = Math.round(
						(progressEvent.loaded / progressEvent.total) * 100
					);
					dispatch(videosUpdateUploadPercentage(percentage));
				},
				"Content-Type": "application/x-www-form-urlencoded",
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(videosAdd({ ...response.data }));
				dispatch(videosSuccess());
			})
			.catch(error => {
				dispatch(videosError(connectionFilter(error)));
			});
	};
};

export const videosEditItem = (
	id,
	title,
	description,
	source,
	thumbnail,
	steps
) => {
	return (dispatch, getState) => {
		dispatch(videosInitiate());
		dispatch(videosUpdateUploadPercentage(0));

		const payload = new FormData();
		payload.append("_method", "PATCH");
		payload.append("id", id);
		payload.append("title", title);
		payload.append("description", description);
		if (source) payload.append("source", source, source.name);
		if (thumbnail) payload.append("thumbnail", thumbnail, thumbnail.name);
		payload.append("steps", JSON.stringify(steps));

		axios
			.getInstance()
			.post(videosUpdateVideoRoute, payload, {
				onUploadProgress: progressEvent => {
					let percentage = Math.round(
						(progressEvent.loaded / progressEvent.total) * 100
					);
					dispatch(videosUpdateUploadPercentage(percentage));
				},
				"Content-Type": "application/x-www-form-urlencoded",
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(videosEdit({ ...response.data }));
				dispatch(videosSuccess());
			})
			.catch(error => {
				dispatch(videosError(connectionFilter(error)));
			});
	};
};

export const videosRemoveItem = id => {
	return (dispatch, getState) => {
		dispatch(videosInitiate());

		const payload = {
			id: id
		};

		axios
			.getInstance()
			.delete(videosDeleteVideoRoute, {
				headers: {
					Authorization: getState().user.token
				},
				data: payload
			})
			.then(() => {
				dispatch(videosRemove(id));
				dispatch(videosSelectItem(null));
				dispatch(videosSuccess());
			})
			.catch(error => {
				dispatch(videosError(connectionFilter(error)));
			});
	};
};
