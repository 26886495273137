import React from "react";
import Header from "../../components/compound/header/header";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

class Frame extends React.Component {
	render() {
		return (
			<React.Fragment>
				{
					this.props.location.pathname != "/fitspritenutrition/pptc/" &&
					<Header
						static={this.props.maintenance}
						heading="Fitsprite"
						fixed
					/>
				}
				<main>{this.props.children}</main>
			</React.Fragment>
		);
	}
}

Frame.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
	maintenance: PropTypes.bool.isRequired
};

export default withRouter(Frame);
