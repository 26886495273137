import { webpush_id } from "../../utils/constants/constants-api";

const withWebPush = WrappedComponent => {
	return class extends WrappedComponent {
		componentDidUpdate(prevProps) {
			if (
				this.props.authenticated &&
				prevProps.authenticated !== this.props.authenticated
			) {
				let OneSignal = window.OneSignal || [];
				OneSignal.push(() => {
					OneSignal.init({
						appId: webpush_id,
						autoRegister: true,
						autoResubscribe: true,
						notifyButton: {
							enable: false
						}
					}).then(() => {
						OneSignal.getUserId(userId => {
							if (userId)
								this.props.userNotificationIDsAdd(userId);
						});
					});
				});
			}
		}

		render() {
			return super.render();
		}
	};
};

export default withWebPush;
