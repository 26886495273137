export const initOAuth = (
	access_token,
	refresh_token,
	expires_in,
	token_type
) => {
	const expiration = new Date().getTime() + expires_in * 1000;
	try {
		localStorage.setItem(
			"oauth",
			JSON.stringify({
				token: "Bearer ".concat(access_token),
				refresh_token: refresh_token,
				expiration: expiration,
				token_type: token_type
			})
		);
	} catch (error) {
		console.log(error);
	}
};

export const readOAuth = () => {
	try {
		return JSON.parse(localStorage.getItem("oauth")) || undefined;
	} catch (error) {
		console.log(error);
	}
};

export const removeOAuth = () => {
	try {
		localStorage.removeItem("oauth");
	} catch (error) {
		console.log(error);
	}
};

export const initCredentials = refresh_token => {
	try {
		localStorage.setItem(
			"oauth_credentials",
			JSON.stringify({
				refresh_token: refresh_token
			})
		);
	} catch (error) {
		console.log(error);
	}
};

export const readCredentials = () => {
	try {
		return (
			JSON.parse(localStorage.getItem("oauth_credentials")) || undefined
		);
	} catch (error) {
		console.log(error);
	}
};

export const removeCredentials = () => {
	try {
		localStorage.removeItem("oauth_credentials");
	} catch (error) {
		console.log(error);
	}
};
