import {
	SETTINGS_WAITING,
	SETTINGS_SUCCESS,
	SETTINGS_FETCH,
	SETTINGS_ERROR,
	SETTINGS_CHANGE_EMAIL,
	SETTINGS_CHANGE_PASSWORD,
	SETTINGS_CHANGE_COMPANY_NAME,
	SETTINGS_CHANGE_COMPANY_ADDRESS,
	SETTINGS_CHANGE_COMPANY_PHOTO,
	SETTINGS_CHANGE_NOTIFICATIONS_EXCEPTIONS,
	SETTINGS_ADD_PACK,
	SETTINGS_ENABLE_PACK,
	SETTINGS_DISABLE_PACK,
	SETTINGS_ADD_MEMBERSHIP,
	SETTINGS_ENABLE_MEMBERSHIP,
	SETTINGS_DISABLE_MEMBERSHIP,
	SETTINGS_ADD_PROMOTION,
	SETTINGS_REMOVE_PROMOTION,
	SETTINGS_ACTIVATE_PROMOTION,
	SETTINGS_DISABLE_PROMOTION
} from "../actions.js";
import axios from "../../utils/functions/axios-instance";

import {
	settingsGetRoute,
	settingsChangeEmailRoute,
	settingsChangeCompanyNameRoute,
	settingsChangeCompanyPhotoRoute,
	settingsChangeCompanyAddressRoute,
	settingsChangePasswordRoute,
	settingsChangeNotificationsExceptionsRoute,
	settingsAddSubscriptionRoute,
	settingsEnableSubscriptionRoute,
	settingsDisableSubscriptionRoute,
	settingsAddPromotionRoute,
	settingsRemovePromotionRoute,
	settingsActivatePromotionRoute,
	settingsDisablePromotionRoute,
	settingsSendGiftRoute
} from "../../utils/constants/constants-api";
import { internalNotificationsAdd } from "./index";
import { connectionFilter } from "../../utils/functions/filtering-functions";
import {
	WORKOUT_MEMBERSHIP,
	SUBSCRIPTION_MEMEBRSHIP,
	SUBSCRIPTION_PACK,
	WORKOUT_MAXINT,
	WORKOUT_GROUP,
	WORKOUT_INDIVIDUAL
} from "../../utils/constants/constants.js";

const settingsSuccess = () => {
	return {
		type: SETTINGS_SUCCESS
	};
};

const settingsInitiate = () => {
	return {
		type: SETTINGS_WAITING
	};
};

const settingsError = error => {
	return {
		type: SETTINGS_ERROR,
		payload: {
			error: error
		}
	};
};

const settingsFetch = settings => {
	return {
		type: SETTINGS_FETCH,
		payload: {
			settings: settings
		}
	};
};

const settingsEmail = email => {
	return {
		type: SETTINGS_CHANGE_EMAIL,
		payload: {
			email: email
		}
	};
};

const settingsPassword = () => {
	return {
		type: SETTINGS_CHANGE_PASSWORD,
		payload: {}
	};
};

const settingsCompanyName = name => {
	return {
		type: SETTINGS_CHANGE_COMPANY_NAME,
		payload: {
			name: name
		}
	};
};

const settingsCompanyAddress = address => {
	return {
		type: SETTINGS_CHANGE_COMPANY_ADDRESS,
		payload: {
			address: address
		}
	};
};

const settingsCompanyPhoto = photo => {
	return {
		type: SETTINGS_CHANGE_COMPANY_PHOTO,
		payload: {
			photo: photo
		}
	};
};

const settingsNotificationsExceptions = dispatchForExceptions => {
	return {
		type: SETTINGS_CHANGE_NOTIFICATIONS_EXCEPTIONS,
		payload: {
			dispatchForExceptions: dispatchForExceptions
		}
	};
};

const settingsAddPack = packItem => {
	return {
		type: SETTINGS_ADD_PACK,
		payload: {
			packItem: packItem
		}
	};
};

const settingsAddMembership = membershipItem => {
	return {
		type: SETTINGS_ADD_MEMBERSHIP,
		payload: {
			membershipItem: membershipItem
		}
	};
};

const settingsAddPromotion = promotionItem => {
	return {
		type: SETTINGS_ADD_PROMOTION,
		payload: {
			promotionItem: promotionItem
		}
	};
};

const settingsRemovePromotion = id => {
	return {
		type: SETTINGS_REMOVE_PROMOTION,
		payload: {
			id: id
		}
	};
};

const settingsActivatePromotion = (id, promotion) => {
	return {
		type: SETTINGS_ACTIVATE_PROMOTION,
		payload: {
			id: id,
			promotion: promotion
		}
	};
};

const settingsDisablePromotion = id => {
	return {
		type: SETTINGS_DISABLE_PROMOTION,
		payload: {
			id: id
		}
	};
};

const settingsEnablePack = id => {
	return {
		type: SETTINGS_ENABLE_PACK,
		payload: {
			id: id
		}
	};
};

const settingsDisablePack = id => {
	return {
		type: SETTINGS_DISABLE_PACK,
		payload: {
			id: id
		}
	};
};

const settingsEnableMembership = id => {
	return {
		type: SETTINGS_ENABLE_MEMBERSHIP,
		payload: {
			id: id
		}
	};
};

const settingsDisableMembership = id => {
	return {
		type: SETTINGS_DISABLE_MEMBERSHIP,
		payload: {
			id: id
		}
	};
};

export const settingsFetchAll = () => {
	return (dispatch, getState) => {
		dispatch(settingsInitiate());

		axios
			.getInstance()
			.get(settingsGetRoute, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(settingsFetch(response.data));
				dispatch(settingsSuccess());
			})
			.catch(error => {
				dispatch(settingsError(connectionFilter(error)));
			});
	};
};

export const settingsChangeEmail = email => {
	return (dispatch, getState) => {
		dispatch(settingsInitiate());

		const payload = {
			email: email
		};

		axios
			.getInstance()
			.patch(settingsChangeEmailRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(settingsEmail(response.data));
				dispatch(settingsSuccess());
				dispatch(
					internalNotificationsAdd(
						"You successfully changed your email address. Don't forget to use the new one when you sign in.",
						"success"
					)
				);
			})
			.catch(error => {
				dispatch(settingsError(connectionFilter(error)));
			});
	};
};

export const settingsChangePassword = (current, password) => {
	return (dispatch, getState) => {
		dispatch(settingsInitiate());

		const payload = {
			current: current,
			password: password
		};

		axios
			.getInstance()
			.patch(settingsChangePasswordRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(() => {
				dispatch(settingsPassword());
				dispatch(settingsSuccess());
				dispatch(
					internalNotificationsAdd(
						"You successfully changed your password. Don't forget to use the new one when you sign in.",
						"success"
					)
				);
			})
			.catch(error => {
				dispatch(settingsError(connectionFilter(error)));
			});
	};
};

export const settingsChangeCompanyName = name => {
	return (dispatch, getState) => {
		dispatch(settingsInitiate());

		const payload = {
			name: name
		};

		axios
			.getInstance()
			.patch(settingsChangeCompanyNameRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(settingsCompanyName(response.data));
				dispatch(settingsSuccess());
				dispatch(
					internalNotificationsAdd(
						"You successfully changed your company's name. Your users will see the new one.",
						"success"
					)
				);
			})
			.catch(error => {
				dispatch(settingsError(connectionFilter(error)));
			});
	};
};

export const settingsChangeCompanyPhoto = source => {
	return (dispatch, getState) => {
		dispatch(settingsInitiate());

		const payload = new FormData();
		payload.append("_method", "PATCH");
		source
			? payload.append("source", source, source.name)
			: payload.append("source", null);

		axios
			.getInstance()
			.post(settingsChangeCompanyPhotoRoute, payload, {
				"Content-Type": "multipart/form-data",
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(settingsCompanyPhoto(response.data));
				dispatch(settingsSuccess());
				dispatch(
					internalNotificationsAdd(
						"You successfully changed your company's photo. Your users will see the new one.",
						"success"
					)
				);
			})
			.catch(error => {
				dispatch(settingsError(connectionFilter(error)));
			});
	};
};

export const settingsChangeCompanyAddress = (street, city, country) => {
	return (dispatch, getState) => {
		dispatch(settingsInitiate());

		const payload = {
			street: street,
			city: city,
			country: country
		};

		axios
			.getInstance()
			.patch(settingsChangeCompanyAddressRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(settingsCompanyAddress(response.data));
				dispatch(settingsSuccess());
				dispatch(
					internalNotificationsAdd(
						"You successfully changed your company's address. Your users will see the new one.",
						"success"
					)
				);
			})
			.catch(error => {
				dispatch(settingsError(connectionFilter(error)));
			});
	};
};

export const settingsChangeNotificationsExceptions = dispatchForExceptions => {
	return (dispatch, getState) => {
		dispatch(settingsInitiate());

		const payload = {
			dispatch: dispatchForExceptions
		};

		axios
			.getInstance()
			.patch(settingsChangeNotificationsExceptionsRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(settingsNotificationsExceptions(response.data));
				dispatch(settingsSuccess());
				dispatch(
					internalNotificationsAdd(
						"You successfully changed your push notifications policy.",
						"success"
					)
				);
			})
			.catch(error => {
				dispatch(settingsError(connectionFilter(error)));
			});
	};
};

export const settingsPackAddItem = (
	name,
	price,
	amount,
	duration,
	trainig_type
) => {
	return (dispatch, getState) => {
		dispatch(settingsInitiate());

		const payload = {
			name: name,
			price: price,
			duration: duration,
			training_amount: amount,
			training_type: trainig_type,
			type: SUBSCRIPTION_PACK
		};

		axios
			.getInstance()
			.post(settingsAddSubscriptionRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(settingsAddPack({ ...response.data }));
				dispatch(settingsSuccess());
				dispatch(
					internalNotificationsAdd(
						"You successfully added a new pack. You users will be able to use it now.",
						"success"
					)
				);
			})
			.catch(error => {
				dispatch(settingsError(connectionFilter(error)));
			});
	};
};

export const settingsMembershipAddItem = (name, price, duration) => {
	return (dispatch, getState) => {
		dispatch(settingsInitiate());

		const payload = {
			name: name,
			price: price,
			duration: duration,
			training_amount: WORKOUT_MAXINT,
			training_type: WORKOUT_MEMBERSHIP,
			type: SUBSCRIPTION_MEMEBRSHIP
		};

		axios
			.getInstance()
			.post(settingsAddSubscriptionRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(settingsAddMembership({ ...response.data }));
				dispatch(settingsSuccess());
				dispatch(
					internalNotificationsAdd(
						"You successfully added a new membership. You users will be able to use it now.",
						"success"
					)
				);
			})
			.catch(error => {
				dispatch(settingsError(connectionFilter(error)));
			});
	};
};

export const settingsPackEnable = id => {
	return (dispatch, getState) => {
		dispatch(settingsInitiate());

		const payload = {
			subscription_id: id
		};

		axios
			.getInstance()
			.patch(settingsEnableSubscriptionRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(() => {
				dispatch(settingsEnablePack(id));
				dispatch(settingsSuccess());
				dispatch(
					internalNotificationsAdd(
						"You successfully enabled a subscription. It is now available for further scheduling again.",
						"info"
					)
				);
			})
			.catch(error => {
				dispatch(settingsError(connectionFilter(error)));
			});
	};
};

export const settingsPackDisable = id => {
	return (dispatch, getState) => {
		dispatch(settingsInitiate());

		const payload = {
			subscription_id: id
		};

		axios
			.getInstance()
			.patch(settingsDisableSubscriptionRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(() => {
				dispatch(settingsDisablePack(id));
				dispatch(settingsSuccess());
				dispatch(
					internalNotificationsAdd(
						"You successfully disabled a subscription. Subscription is not available for further scheduling.",
						"warning"
					)
				);
			})
			.catch(error => {
				dispatch(settingsError(connectionFilter(error)));
			});
	};
};

export const settingsMembershipEnable = id => {
	return (dispatch, getState) => {
		dispatch(settingsInitiate());

		const payload = {
			subscription_id: id
		};

		axios
			.getInstance()
			.patch(settingsEnableSubscriptionRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(() => {
				dispatch(settingsEnableMembership(id));
				dispatch(settingsSuccess());
				dispatch(
					internalNotificationsAdd(
						"You successfully enabled a membership. It is now available for further scheduling again.",
						"info"
					)
				);
			})
			.catch(error => {
				dispatch(settingsError(connectionFilter(error)));
			});
	};
};

export const settingsMembershipDisable = id => {
	return (dispatch, getState) => {
		dispatch(settingsInitiate());

		const payload = {
			subscription_id: id
		};

		axios
			.getInstance()
			.patch(settingsDisableSubscriptionRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(() => {
				dispatch(settingsDisableMembership(id));
				dispatch(settingsSuccess());
				dispatch(
					internalNotificationsAdd(
						"You successfully disabled a membership. Membership is not available for further scheduling.",
						"warning"
					)
				);
			})
			.catch(error => {
				dispatch(settingsError(connectionFilter(error)));
			});
	};
};

export const settingsPromotionAddItem = (name, percentage, duration) => {
	return (dispatch, getState) => {
		dispatch(settingsInitiate());

		const payload = {
			name: name,
			percentage: percentage,
			duration: duration
		};

		axios
			.getInstance()
			.post(settingsAddPromotionRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(settingsAddPromotion({ ...response.data }));
				dispatch(settingsSuccess());
			})
			.catch(error => {
				dispatch(settingsError(connectionFilter(error)));
			});
	};
};

export const settingsPromotionRemoveItem = id => {
	return (dispatch, getState) => {
		dispatch(settingsInitiate());

		const payload = {
			id: id
		};

		axios
			.getInstance()
			.delete(settingsRemovePromotionRoute, {
				headers: {
					Authorization: getState().user.token
				},
				data: payload
			})
			.then(() => {
				dispatch(settingsRemovePromotion(id));
				dispatch(settingsSuccess());
			})
			.catch(error => {
				dispatch(settingsError(connectionFilter(error)));
			});
	};
};

export const settingsPromotionEnable = (id, promotion) => {
	return (dispatch, getState) => {
		dispatch(settingsInitiate());

		const payload = {
			subscription_id: id,
			promotion_id: promotion
		};

		axios
			.getInstance()
			.patch(settingsActivatePromotionRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(() => {
				dispatch(settingsActivatePromotion(id, promotion));
				dispatch(settingsSuccess());
				dispatch(
					internalNotificationsAdd(
						"You successfully activated a promotion on a subscription. It will be active until you disable it.",
						"info"
					)
				);
			})
			.catch(error => {
				dispatch(settingsError(connectionFilter(error)));
			});
	};
};

export const settingsPromotionDisable = id => {
	return (dispatch, getState) => {
		dispatch(settingsInitiate());

		const payload = {
			subscription_id: id
		};

		axios
			.getInstance()
			.patch(settingsDisablePromotionRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(() => {
				dispatch(settingsDisablePromotion(id));
				dispatch(settingsSuccess());
				dispatch(
					internalNotificationsAdd(
						"You successfully disabled a promotion on a subscription. Subscription is priced to initial value.",
						"warning"
					)
				);
			})
			.catch(error => {
				dispatch(settingsError(connectionFilter(error)));
			});
	};
};

export const settingsSendGift = (userId, group) => {
	return (dispatch, getState) => {
		dispatch(settingsInitiate());

		const payload = {
			user_id: userId,
			duration: 30,
			training_amount: 1,
			training_type: group ? WORKOUT_GROUP : WORKOUT_INDIVIDUAL,
			type: SUBSCRIPTION_PACK
		};

		axios
			.getInstance()
			.patch(settingsSendGiftRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(() => {
				dispatch(settingsSuccess());
				dispatch(
					internalNotificationsAdd(
						"You successfully sent a Gift card to your user! They will be able to use it now.",
						"info"
					)
				);
			})
			.catch(error => {
				dispatch(settingsError(connectionFilter(error)));
			});
	};
};
