import {
	SCHEDULE_FETCH_WORKING_DAYS,
	SCHEDULE_FETCH_EXCEPTIONS,
	SCHEDULE_FETCH_RECURRING_EXCEPTIONS,
	SCHEDULE_FETCH_HOLIDAYS,
	SCHEDULE_SET_WORKING_DAYS,
	SCHEDULE_SET_EXCEPTIONS,
	SCHEDULE_SET_RECURRING_EXCEPTIONS,
	SCHEDULE_SET_HOLIDAYS,
	SCHEDULE_CHANGE_MONTH,
	SCHEDULE_REMOVE_RECURRING_EXCEPTION,
	SCHEDULE_REMOVE_EXCEPTION,
	SCHEDULE_REMOVE_HOLIDAY,
	SCHEDULE_SUCCESS,
	SCHEDULE_WAITING,
	SCHEDULE_ERROR
} from "../actions.js";

import {
	bridgeAgendaForExceptions,
	bridgeAgendaForWorkingDays
} from "../../utils/functions/bridge-agenda";

const initialState = {
	workingDays: {
		Monday: undefined,
		Tuesday: undefined,
		Wednesday: undefined,
		Thursday: undefined,
		Friday: undefined,
		Saturday: undefined,
		Sunday: undefined
	},
	exceptions: [],
	recurringExceptions: [],
	holidays: [],
	agendaWorkingDays: {},
	agendaExceptions: {},
	agendaHolidays: {},
	month: new Date(),
	waiting: false,
	error: null
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SCHEDULE_FETCH_WORKING_DAYS:
			return {
				...state,
				workingDays: { ...action.payload.workingDays },
				agendaWorkingDays: {
					...bridgeAgendaForWorkingDays(
						action.payload.workingDays,
						state.month
					)
				}
			};
		case SCHEDULE_FETCH_EXCEPTIONS:
			return {
				...state,
				exceptions: action.payload.exceptions.map(element => ({
					...element
				})),
				agendaExceptions: {
					...bridgeAgendaForExceptions(
						action.payload.exceptions,
						state.month,
						"EXCEPTION"
					)
				}
			};
		case SCHEDULE_FETCH_RECURRING_EXCEPTIONS:
			return {
				...state,
				recurringExceptions: action.payload.recurringExceptions.map(
					element => ({ ...element })
				)
			};
		case SCHEDULE_FETCH_HOLIDAYS:
			return {
				...state,
				holidays: action.payload.holidays.map(element => ({
					...element
				})),
				agendaHolidays: {
					...bridgeAgendaForExceptions(
						action.payload.holidays,
						state.month,
						"HOLIDAY"
					)
				}
			};
		case SCHEDULE_SET_WORKING_DAYS:
			return {
				...state,
				workingDays: { ...action.payload.workingDays },
				agendaWorkingDays: {
					...bridgeAgendaForWorkingDays(
						action.payload.workingDays,
						state.month
					)
				}
			};
		case SCHEDULE_SET_EXCEPTIONS: {
			const exceptions = state.exceptions
				.map(element => ({ ...element }))
				.concat(
					action.payload.exceptions.map(exception => ({
						...exception
					}))
				);
			return {
				...state,
				exceptions: exceptions,
				agendaExceptions: {
					...bridgeAgendaForExceptions(
						exceptions,
						state.month,
						"EXCEPTION"
					)
				}
			};
		}
		case SCHEDULE_SET_RECURRING_EXCEPTIONS:
			return {
				...state,
				recurringExceptions: [
					...state.recurringExceptions,
					action.payload.recurringExceptions
				]
			};
		case SCHEDULE_SET_HOLIDAYS: {
			const holidays = state.holidays
				.map(element => ({ ...element }))
				.concat(
					action.payload.holidays.map(holiday => ({
						...holiday
					}))
				);
			return {
				...state,
				holidays: holidays,
				agendaHolidays: {
					...bridgeAgendaForExceptions(
						holidays,
						state.month,
						"HOLIDAY"
					)
				}
			};
		}
		case SCHEDULE_CHANGE_MONTH:
			return {
				...state,
				agendaWorkingDays: {
					...bridgeAgendaForWorkingDays(
						state.workingDays,
						action.payload.month
					)
				},
				agendaExceptions: {
					...bridgeAgendaForExceptions(
						state.exceptions,
						action.payload.month,
						"EXCEPTION"
					)
				},
				agendaHolidays: {
					...bridgeAgendaForExceptions(
						state.holidays,
						action.payload.month,
						"HOLIDAY"
					)
				},
				month: action.payload.month
			};
		case SCHEDULE_REMOVE_RECURRING_EXCEPTION:
			return {
				...state,
				recurringExceptions: state.recurringExceptions
					.map(element => ({ ...element }))
					.filter(element => element.id !== action.payload.id)
			};
		case SCHEDULE_REMOVE_EXCEPTION: {
			const exceptions = state.exceptions
				.map(element => ({ ...element }))
				.filter(
					element => action.payload.ids.indexOf(element.id) === -1
				);
			return {
				...state,
				exceptions: exceptions,
				agendaExceptions: {
					...bridgeAgendaForExceptions(
						exceptions,
						state.month,
						"EXCEPTION"
					)
				}
			};
		}
		case SCHEDULE_REMOVE_HOLIDAY: {
			const holidays = state.holidays
				.map(element => ({ ...element }))
				.filter(
					element => action.payload.ids.indexOf(element.id) === -1
				);
			return {
				...state,
				holidays: holidays,
				agendaHolidays: {
					...bridgeAgendaForExceptions(
						holidays,
						state.month,
						"HOLIDAY"
					)
				}
			};
		}
		case SCHEDULE_SUCCESS:
			return {
				...state,
				waiting: false,
				error: null
			};
		case SCHEDULE_WAITING:
			return {
				...state,
				waiting: true,
				error: null
			};
		case SCHEDULE_ERROR:
			return {
				...state,
				error: action.payload.error,
				waiting: false
			};
		default:
			return state;
	}
};

export default reducer;
