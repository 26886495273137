import {
	TIMELINE_FETCH,
	TIMELINE_WAITING,
	TIMELINE_SUCCESS,
	TIMELINE_ERROR
} from "../actions.js";

const initialState = {
	timeline: [],
	waiting: false,
	error: null
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case TIMELINE_FETCH:
			return {
				...state,
				timeline: action.payload.timelineItems.map(element => ({
					...element
				}))
			};
		case TIMELINE_SUCCESS:
			return {
				...state,
				waiting: false,
				error: null
			};
		case TIMELINE_WAITING:
			return {
				...state,
				waiting: true,
				error: null
			};
		case TIMELINE_ERROR:
			return {
				...state,
				error: action.payload.error,
				waiting: false
			};
		default:
			return state;
	}
};

export default reducer;
