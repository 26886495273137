export const initLocale = () => {
	try {
		let locale = readLocale();
		if (!locale) {
			locale = "en-US";
			setLocale(locale);
		}
		return locale;
	} catch (error) {
		console.log(error);
	}
};

export const readLocale = () => {
	try {
		return localStorage.getItem("locale") || undefined;
	} catch (error) {
		console.log(error);
	}
};

export const removeLocale = () => {
	try {
		localStorage.removeItem("locale");
	} catch (error) {
		console.log(error);
	}
};

export const setLocale = locale => {
	localStorage.setItem("locale", locale);
};
