// DEVELOPMENT SERVER
// export const backendRoot = "https://fitness.5mautomatisering.nl:444/";

// PRODUCTION SERVER
export const backendRoot = "https://admin.fitsprite.nl/";

export const client_id = "1_32swugc71qww8g800kw0o044kgs4sokk8g4osw488kgc8kok4s";
export const client_secret =
	"21srlr6iny5co4w8wgsokgscccwg004oks40048gg0kgk8c0g8";
export const webpush_id = "1de95acc-3df1-4a79-83d9-34cc7c59b147";

export const backendRouteWorkouts = source =>
	backendRoot.concat("images/trainings/", source);
export const backendRouteEquipment = source =>
	backendRoot.concat("images/equipments/", source);
export const backendRouteVideos = source =>
	backendRoot.concat("images/videos/", source);
export const backendRouteCompany = source =>
	backendRoot.concat("images/companies/", source);
export const backendRoutePlayback = source =>
	backendRoot.concat("videos/videos/", source);

export const authRoute = "oauth/v2/token";
export const authRegisterRoute = "auth/register";
export const authMailRequestRoute = "mails/send-request-service-mail";
export const authMailDemoRoute = "mails/send-request-demo-mail";

export const userDataRoute = "v1/users/get-user-data";
export const userAddNotificationIDRoute =
	"v1/notifications/add-notification-id";
export const usersGetRoute = "v1/users/get-users";
export const usersGetUserRoute = "v1/users/get-user";

export const equipmentCreateRoute = "v1/equipments/create-equipment";
export const equipmentUpdateRoute = "v1/equipments/update-equipment";
export const equipmentDeleteRoute = "v1/equipments/delete-equipment";
export const equipmentGetEquipmentRoute = "v1/equipments/get-equipment";
export const equipmentGetEquipmentsRoute = "v1/equipments/get-equipments";

export const schedulesGetMonthlyScheduledRoute =
	"v1/schedules/get-monthly-scheduled";
export const schedulesGetDailyScheduleRoute =
	"v1/schedules/get-daily-scheduled";

export const sessionsCreateGroupRoute = "v1/sessions/create-group-session";
export const sessionsCreateRoute = "v1/sessions/create-session";
export const sessionsUpdateRoute = "v1/sessions/update-session";
export const sessionsChangeStatusRoute = "v1/sessions/change-session-status";
export const sessionsGetSessionRoute = "v1/sessions/get-session";
export const sessionsGetSessionsRoute = "v1/sessions/get-sessions";
export const sessionsGetUserSessionsRoute = "v1/sessions/get-user-sessions";
export const sessionsGetActiveSessionsRoute = "v1/sessions/get-active-sessions";
export const sessionsGetHandledSessionsRoute =
	"v1/sessions/get-handled-sessions";
export const sessionsGetUnhandledSessionsRoute =
	"v1/sessions/get-unhandled-sessions";
export const sessionsRescheduleRoute = "v1/sessions/reschedule-session";
export const sessionsGetWeeklySessionsRoute = "v1/sessions/get-weekly-sessions";
export const sessionsGetWeeklySessionsHistoryRoute =
	"v1/sessions/get-history-sessions";
export const sessionsCancelRoute = "v1/sessions/cancel-group-session";

export const trainingsCreateTrainingRoute = "v1/trainings/create-training";
export const trainingsUpdateTrainingRoute = "v1/trainings/update-training";
export const trainingsDeleteTrainingRoute = "v1/trainings/delete-training";
export const trainingsGetTrainingRoute = "v1/trainings/get-training";
export const trainingsGetTrainingsRoute = "v1/trainings/get-trainings";

export const videosCreateVideoRoute = "v1/videos/create-video";
export const videosUpdateVideoRoute = "v1/videos/update-video";
export const videosDeleteVideoRoute = "v1/videos/delete-video";
export const videosGetVideoRoute = "v1/videos/get-video";
export const videosGetVideosRoute = "v1/videos/get-videos";

export const workHoursCreateWorkingDaysRoute =
	"v1/work-hours/create-working-days";
export const workHoursGetWorkingDaysRoute = "v1/work-hours/get-working-days";
export const workHoursCreateExceptionsRoute = "v1/work-hours/create-exceptions";
export const workHoursGetExceptionsRoute = "v1/work-hours/get-exceptions";
export const workHoursCreateRecurringExceptionsRoute =
	"v1/work-hours/create-recurring-exceptions";
export const workHoursGetRecurringExceptionsRoute =
	"v1/work-hours/get-recurring-exceptions";
export const workHoursCreateHolidayRoute = "v1/work-hours/create-holiday";
export const workHoursGetHolidaysRoute = "v1/work-hours/get-holidays";
export const workHoursRemoveExceptionRoute = "v1/work-hours/remove-exceptions";
export const workHoursRemoveHolidayRoute = "v1/work-hours/remove-holiday";
export const workHoursRemoveRecurringExceptionRoute =
	"v1/work-hours/remove-recurring-exception";

export const statisticsGetRoute = "v1/statistics/get-statistic";

export const paymentsGetRoute = "v1/payments/get-payments";
export const paymentsPendingGetRoute = "v1/payments/get-pending-payments";
export const paymentsLocalPendingGetRoute =
	"v1/payments/get-unpaid-local-payments";
export const paymentsMonthlyReportGetRoute = "v1/payments/monthly-report";
export const paymentsChangeStatusRoute = "v1/payments/change-payment-status";
export const paymentsUpdateRoute = "v1/payments/update-payment";

export const notificationsGetRoute = "v1/notifications/get-user-notifications";
export const notificationsPushAddRoute = "v1/notifications/send-notifications";

export const settingsGetRoute = "v1/settings/get-settings";
export const settingsChangeEmailRoute = "v1/settings/change-email";
export const settingsChangePasswordRoute = "v1/settings/change-password";
export const settingsChangeCompanyNameRoute = "v1/settings/change-company-name";
export const settingsChangeCompanyPhotoRoute =
	"v1/settings/change-company-photo";
export const settingsChangeCompanyAddressRoute =
	"v1/settings/change-company-address";
export const settingsChangeNotificationsExceptionsRoute =
	"v1/settings/change-notifications-exceptions";
export const settingsAddSubscriptionRoute = "v1/settings/create-subscription";
export const settingsEnableSubscriptionRoute =
	"v1/settings/active-subscription";
export const settingsDisableSubscriptionRoute =
	"v1/settings/disable-subscription";
export const settingsAddPromotionRoute = "v1/settings/add-promotion";
export const settingsRemovePromotionRoute = "v1/settings/remove-promotion";
export const settingsActivatePromotionRoute = "v1/settings/activate-promotion";
export const settingsDisablePromotionRoute = "v1/settings/disable-promotion";
export const settingsSendGiftRoute = "v1/settings/send-gift";
