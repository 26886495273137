import {
	USER_CLEAR_CREDENTIALS,
	USER_UPDATE_TOKEN,
	USER_FETCH_DATA,
	USER_ADD_NOTIFICATION_ID,
	USER_WAITING,
	USER_SUCCESS,
	USER_ERROR
} from "../actions.js";

const initialState = {
	expiration: null,
	token: null,
	refreshToken: null,
	data: {
		role: null,
		email: null,
		firstName: null,
		lastName: null,
		notificationIds: []
	}
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case USER_UPDATE_TOKEN:
			return {
				...state,
				expiration: action.payload.expiration,
				token: action.payload.token,
				refreshToken: action.payload.refreshToken
			};
		case USER_FETCH_DATA:
			return {
				...state,
				data: {
					...state.data,
					role: action.payload.data.role,
					email: action.payload.data.email,
					firstName: action.payload.data.first_name,
					lastName: action.payload.data.last_name,
					notificationIds: [...action.payload.data.notification_ids]
				}
			};
		case USER_ADD_NOTIFICATION_ID: {
			const array = state.data.notificationIds.map(element => element);
			array.push(action.payload.notificationID);
			return {
				...state,
				data: {
					...state.data,
					notificationIds: array
				}
			};
		}
		case USER_SUCCESS:
			return {
				...state,
				waiting: false,
				error: null
			};
		case USER_WAITING:
			return {
				...state,
				waiting: true,
				error: null
			};
		case USER_ERROR:
			return {
				...state,
				error: action.payload.error,
				waiting: false
			};
		case USER_CLEAR_CREDENTIALS:
			return initialState;
		default:
			return state;
	}
};

export default reducer;
