import base from "axios";
import { backendRoot } from "../constants/constants-api";
import maintenanceService from "../services/maintenance-service";

export default class axios {
	static instance = null;

	static getInstance() {
		if (axios.instance == null) {
			axios.instance = base.create({
				baseURL: backendRoot
			});

			axios.instance.interceptors.request.use(
				function(config) {
					// request interceptor could be here...

					return config;
				},
				function(error) {
					// error interceptor could be here...

					return Promise.reject(error);
				}
			);

			axios.instance.interceptors.response.use(
				function(response) {
					// response interceptor could be here...

					return response;
				},
				function(error) {
					if (
						error.response &&
						error.response.status &&
						error.response.status === 503
					)
						maintenanceService.enable();

					return Promise.reject(error);
				}
			);
		}
		return axios.instance;
	}
}
