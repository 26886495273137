import {
	EQUIPMENT_FETCH,
	EQUIPMENT_ADD,
	EQUIPMENT_REMOVE,
	EQUIPMENT_EDIT,
	EQUIPMENT_SELECT,
	EQUIPMENT_SUCCESS,
	EQUIPMENT_WAITING,
	EQUIPMENT_ERROR
} from "../actions.js";
import axios from "../../utils/functions/axios-instance";

import {
	equipmentCreateRoute,
	equipmentGetEquipmentsRoute,
	equipmentUpdateRoute,
	equipmentDeleteRoute
} from "../../utils/constants/constants-api";
import { connectionFilter } from "../../utils/functions/filtering-functions";

const equipmentSuccess = () => {
	return {
		type: EQUIPMENT_SUCCESS
	};
};

const equipmentInitiate = () => {
	return {
		type: EQUIPMENT_WAITING
	};
};

const equipmentError = error => {
	return {
		type: EQUIPMENT_ERROR,
		payload: {
			error: error
		}
	};
};

const equipmentFetch = equipment => {
	return {
		type: EQUIPMENT_FETCH,
		payload: {
			equipment: equipment
		}
	};
};

const equipmentAdd = equipmentItem => {
	return {
		type: EQUIPMENT_ADD,
		payload: {
			equipmentItem: equipmentItem
		}
	};
};

const equipmentRemove = id => {
	return {
		type: EQUIPMENT_REMOVE,
		payload: {
			id: id
		}
	};
};

const equipmentEdit = equipmentItem => {
	return {
		type: EQUIPMENT_EDIT,
		payload: {
			equipmentItem: equipmentItem
		}
	};
};

export const equipmentSelectItem = id => {
	return {
		type: EQUIPMENT_SELECT,
		payload: {
			id: id
		}
	};
};

export const equipmentFetchAll = () => {
	return (dispatch, getState) => {
		dispatch(equipmentInitiate());

		axios
			.getInstance()
			.get(equipmentGetEquipmentsRoute, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(equipmentFetch(response.data));
				dispatch(equipmentSuccess());
			})
			.catch(error => {
				dispatch(equipmentError(connectionFilter(error)));
			});
	};
};

export const equipmentAddItem = (name, description, source) => {
	return (dispatch, getState) => {
		dispatch(equipmentInitiate());

		const payload = new FormData();
		payload.append("name", name);
		payload.append("description", description);
		source
			? payload.append("source", source, source.name)
			: payload.append("source", null);

		axios
			.getInstance()
			.post(equipmentCreateRoute, payload, {
				"Content-Type": "application/x-www-form-urlencoded",
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(equipmentAdd({ ...response.data }));
				dispatch(equipmentSuccess());
			})
			.catch(error => {
				dispatch(equipmentError(connectionFilter(error)));
			});
	};
};

export const equipmentEditItem = (id, name, description, source) => {
	return (dispatch, getState) => {
		dispatch(equipmentInitiate());

		const payload = new FormData();
		payload.append("_method", "PATCH");
		payload.append("id", id);
		payload.append("name", name);
		payload.append("description", description);
		source
			? payload.append("source", source, source.name)
			: payload.append("source", null);

		axios
			.getInstance()
			.post(equipmentUpdateRoute, payload, {
				"Content-Type": "application/x-www-form-urlencoded",
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(equipmentEdit({ ...response.data }));
				dispatch(equipmentSuccess());
			})
			.catch(error => {
				dispatch(equipmentError(connectionFilter(error)));
			});
	};
};

export const equipmentRemoveItem = id => {
	return (dispatch, getState) => {
		dispatch(equipmentInitiate());

		const payload = {
			id: id
		};

		axios
			.getInstance()
			.delete(equipmentDeleteRoute, {
				headers: {
					Authorization: getState().user.token
				},
				data: payload
			})
			.then(() => {
				dispatch(equipmentRemove(id));
				dispatch(equipmentSelectItem(null));
				dispatch(equipmentSuccess());
			})
			.catch(error => {
				dispatch(equipmentError(connectionFilter(error)));
			});
	};
};
