import React from "react";
import Dropdown from "../../../interface/dropdown/dropdown";
import Notifications from "@material-ui/icons/Notifications";
import AccountBox from "@material-ui/icons/AccountBox";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
	authLogout,
	notificationsFetchAll
} from "../../../../store/actions/index";

const headerMenu = props => (
	<React.Fragment>
		<Dropdown
			action={props.notificationsFetchAll}
			dropdownHeader="Your most recent notifications"
			buttonIcon={Notifications}
			dropdownList={props.notifications.map(notification => ({
				label: notification.description,
				seen: notification.seen,
				notification: true
			}))}
			dropdownCount={props.notifications.reduce(
				(acc, cur) => (cur.seen === false ? ++acc : acc),
				0
			)}
		/>
		<Dropdown
			dropdownHeader="Account menu"
			buttonIcon={AccountBox}
			dropdownList={[
				{
					label: "Ask a question",
					action: () =>
						(window.location.href = "mailto:info@5mict.com")
				},
				{
					label: "Report a problem",
					action: () =>
						(window.location.href = "mailto:info@5mict.com")
				},
				{
					label: "Log out",
					action: props.authLogout
				}
			]}
		/>
	</React.Fragment>
);

const mapStateToProps = state => {
	return {
		notifications: state.notifications.notifications
	};
};

const mapDispatchToProps = dispatch => {
	return {
		authLogout: () => dispatch(authLogout()),
		notificationsFetchAll: () => dispatch(notificationsFetchAll())
	};
};

headerMenu.propTypes = {
	notifications: PropTypes.arrayOf(PropTypes.object),
	notificationsFetchAll: PropTypes.func.isRequired,
	authLogout: PropTypes.func
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(React.memo(headerMenu));
