import { combineReducers } from "redux";
import authReducer from "./reducers/auth";
import userReducer from "./reducers/user";
import internalReducer from "./reducers/internal";
import settingsReducer from "./reducers/settings";
import equipmentReducer from "./reducers/equipment";
import videosReducer from "./reducers/videos";
import workoutsReducer from "./reducers/workouts";
import scheduleReducer from "./reducers/schedule";
import sessionsReducer from "./reducers/sessions";
import usersReducer from "./reducers/users";
import statisticsReducer from "./reducers/statistics";
import notificationsReducer from "./reducers/notifications";
import availabilityReducer from "./reducers/availability";
import timelineReducer from "./reducers/timeline";
import paymentsReducer from "./reducers/payments";

export default combineReducers({
	auth: authReducer,
	user: userReducer,
	internal: internalReducer,
	settings: settingsReducer,
	equipment: equipmentReducer,
	videos: videosReducer,
	workouts: workoutsReducer,
	schedule: scheduleReducer,
	sessions: sessionsReducer,
	users: usersReducer,
	statistics: statisticsReducer,
	availability: availabilityReducer,
	notifications: notificationsReducer,
	timeline: timelineReducer,
	payments: paymentsReducer
});
