import {
	TIMELINE_FETCH,
	TIMELINE_WAITING,
	TIMELINE_SUCCESS,
	TIMELINE_ERROR
} from "../actions.js";
import axios from "../../utils/functions/axios-instance";

import { schedulesGetDailyScheduleRoute } from "../../utils/constants/constants-api";
import { connectionFilter } from "../../utils/functions/filtering-functions";

const timelineSuccess = () => {
	return {
		type: TIMELINE_SUCCESS
	};
};

const timelineInitiate = () => {
	return {
		type: TIMELINE_WAITING
	};
};

const timelineError = error => {
	return {
		type: TIMELINE_ERROR,
		payload: {
			error: error
		}
	};
};

const timelineFetch = timelineItems => {
	return {
		type: TIMELINE_FETCH,
		payload: {
			timelineItems: timelineItems
		}
	};
};

export const timelineFetchAll = (date, training_id) => {
	return (dispatch, getState) => {
		dispatch(timelineInitiate());

		axios
			.getInstance()
			.get(schedulesGetDailyScheduleRoute, {
				headers: { Authorization: getState().user.token },
				params: { training_id: training_id, date: date }
			})
			.then(response => {
				dispatch(timelineFetch(response.data));
				dispatch(timelineSuccess());
			})
			.catch(error => {
				dispatch(timelineError(connectionFilter(error)));
			});
	};
};
