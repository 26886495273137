import React from "react";
import { LocaleContext } from "../../context/localeContext";
import { initLocale, setLocale } from "../../utils/functions/locale";

function withMultilingual(WrappedComponent) {
	return class extends React.Component {
		state = {
			locale: initLocale()
		};

		changeLanguage = locale => {
			this.setState(
				{
					locale
				},
				() => {
					setLocale(locale);
				}
			);
		};

		render() {
			return (
				<LocaleContext.Provider
					value={{
						locale: this.state.locale,
						changeLanguage: this.changeLanguage
					}}
				>
					<WrappedComponent {...this.props} />
				</LocaleContext.Provider>
			);
		}
	};
}

export default withMultilingual;
