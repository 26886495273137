import React from "react";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import CssBaseline from "@material-ui/core/CssBaseline";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function withMaterial(Component) {
	return class extends React.Component {
		theme = createMuiTheme({
			palette: {
				primary: {
					light: "#00c9e1",
					main: "#00a3b8",
					dark: "#00879c"
				},
				secondary: {
					light: "#ff1e68",
					main: "#e91e63",
					dark: "#991545"
				},
				ternary: {
					light: "#f0f4c3",
					main: "#e6ee9c",
					dark: "#dce775"
				}
			}
		});

		render() {
			return (
				<ThemeProvider theme={this.theme}>
					<CssBaseline />
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<Component />
					</MuiPickersUtilsProvider>
				</ThemeProvider>
			);
		}
	};
}

export default withMaterial;
