import {
	SESSIONS_FETCH,
	SESSIONS_FETCH_HANDLED,
	SESSIONS_FETCH_WEEKLY,
	SESSIONS_FETCH_WEEKLY_HISTORY,
	SESSIONS_FETCH_USER,
	SESSIONS_ACCEPT,
	SESSIONS_REJECT,
	SESSIONS_SELECT,
	SESSIONS_CANCEL,
	SESSIONS_SUCCESS,
	SESSIONS_WAITING,
	SESSIONS_ERROR
} from "../actions.js";
import axios from "../../utils/functions/axios-instance";

import {
	sessionsChangeStatusRoute,
	sessionsGetHandledSessionsRoute,
	sessionsGetWeeklySessionsRoute,
	sessionsGetWeeklySessionsHistoryRoute,
	sessionsGetUnhandledSessionsRoute,
	sessionsGetUserSessionsRoute,
	sessionsGetSessionRoute,
	sessionsRescheduleRoute,
	sessionsCreateGroupRoute,
	sessionsCancelRoute
} from "../../utils/constants/constants-api";

import { internalNotificationsAdd } from "./index";
import { connectionFilter } from "../../utils/functions/filtering-functions";

const sessionsSuccess = () => {
	return {
		type: SESSIONS_SUCCESS
	};
};

const sessionsInitiate = () => {
	return {
		type: SESSIONS_WAITING
	};
};

const sessionsError = error => {
	return {
		type: SESSIONS_ERROR,
		payload: {
			error: error
		}
	};
};

const sessionsFetch = sessions => {
	return {
		type: SESSIONS_FETCH,
		payload: {
			sessions: sessions
		}
	};
};

const sessionsFetchHandled = sessions => {
	return {
		type: SESSIONS_FETCH_HANDLED,
		payload: {
			sessions: sessions
		}
	};
};

const sessionsFetchWeekly = sessions => {
	return {
		type: SESSIONS_FETCH_WEEKLY,
		payload: {
			sessions: sessions
		}
	};
};

const sessionsFetchWeeklyHistory = sessions => {
	return {
		type: SESSIONS_FETCH_WEEKLY_HISTORY,
		payload: {
			sessions: sessions
		}
	};
};

const sessionsFetchUser = sessions => {
	return {
		type: SESSIONS_FETCH_USER,
		payload: {
			sessions: sessions
		}
	};
};

const sessionsAccept = id => {
	return {
		type: SESSIONS_ACCEPT,
		payload: {
			id: id
		}
	};
};

const sessionsReject = id => {
	return {
		type: SESSIONS_REJECT,
		payload: {
			id: id
		}
	};
};

const sessionsCancel = id => {
	return {
		type: SESSIONS_CANCEL,
		payload: {
			id: id
		}
	};
};

export const sessionsSelect = selected => {
	return {
		type: SESSIONS_SELECT,
		payload: {
			selected: selected
		}
	};
};

export const sessionsSelectItem = id => {
	return (dispatch, getState) => {
		dispatch(sessionsInitiate());

		if (id) {
			const payload = {
				params: { id: id },
				headers: {
					Authorization: getState().user.token
				}
			};

			axios
				.getInstance()
				.get(sessionsGetSessionRoute, payload)
				.then(response => {
					dispatch(sessionsSelect(response.data));
					dispatch(sessionsSuccess());
				})
				.catch(error => {
					dispatch(sessionsError(connectionFilter(error)));
				});
		} else {
			dispatch(sessionsSelect(null));
			dispatch(sessionsSuccess());
		}
	};
};

export const sessionsRescheduleItem = (id, start, end, date) => {
	return (dispatch, getState) => {
		dispatch(sessionsInitiate());

		const payload = {
			id: id,
			start: start,
			end: end,
			date: date
		};

		axios
			.getInstance()
			.patch(sessionsRescheduleRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(() => {
				dispatch(sessionsSelect(null));
				dispatch(sessionsFetchWeeklyAll());
				dispatch(
					internalNotificationsAdd(
						"You just sent a Reschedule request, you can follow the status of that session and user's action.",
						"success"
					)
				);
			})
			.catch(error => {
				dispatch(sessionsError(connectionFilter(error)));
			});
	};
};

export const sessionsCancelItem = id => {
	return (dispatch, getState) => {
		dispatch(sessionsInitiate());

		const payload = {
			id: id
		};

		axios
			.getInstance()
			.patch(sessionsCancelRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(() => {
				dispatch(sessionsCancel(id));
				dispatch(sessionsSelect(null));
				dispatch(sessionsFetchWeeklyAll());
				dispatch(
					internalNotificationsAdd(
						"You just canceled previously scheduled session. Your users won't be able to schedule accordingly.",
						"warning"
					)
				);
			})
			.catch(error => {
				dispatch(sessionsError(connectionFilter(error)));
			});
	};
};

export const sessionsFetchAll = () => {
	return (dispatch, getState) => {
		dispatch(sessionsInitiate());

		axios
			.getInstance()
			.get(sessionsGetUnhandledSessionsRoute, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(sessionsFetch(response.data));
				dispatch(sessionsSuccess());
			})
			.catch(error => {
				dispatch(sessionsError(connectionFilter(error)));
			});
	};
};

export const sessionsFetchHandledAll = () => {
	return (dispatch, getState) => {
		dispatch(sessionsInitiate());

		axios
			.getInstance()
			.get(sessionsGetHandledSessionsRoute, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(sessionsFetchHandled(response.data));
				dispatch(sessionsSuccess());
			})
			.catch(error => {
				dispatch(sessionsError(connectionFilter(error)));
			});
	};
};

export const sessionsFetchWeeklyAll = () => {
	return (dispatch, getState) => {
		dispatch(sessionsInitiate());

		axios
			.getInstance()
			.get(sessionsGetWeeklySessionsRoute, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(sessionsFetchWeekly(response.data));
				dispatch(sessionsSuccess());
			})
			.catch(error => {
				dispatch(sessionsError(connectionFilter(error)));
			});
	};
};

export const sessionsFetchWeeklyHistoryAll = () => {
	return (dispatch, getState) => {
		dispatch(sessionsInitiate());

		axios
			.getInstance()
			.get(sessionsGetWeeklySessionsHistoryRoute, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(sessionsFetchWeeklyHistory(response.data));
				dispatch(sessionsSuccess());
			})
			.catch(error => {
				dispatch(sessionsError(connectionFilter(error)));
			});
	};
};

export const sessionsFetchForUser = id => {
	return (dispatch, getState) => {
		dispatch(sessionsInitiate());

		const payload = {
			id: id
		};

		axios
			.getInstance()
			.get(sessionsGetUserSessionsRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(sessionsFetchUser(response.data));
				dispatch(sessionsSuccess());
			})
			.catch(error => {
				dispatch(sessionsError(connectionFilter(error)));
			});
	};
};

export const sessionsAcceptItem = id => {
	return (dispatch, getState) => {
		dispatch(sessionsInitiate());

		const payload = {
			id: id,
			status: "ACCEPTED"
		};

		axios
			.getInstance()
			.patch(sessionsChangeStatusRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(sessionsAccept(response.data));
				dispatch(sessionsSuccess());
				dispatch(sessionsFetchAll());
				dispatch(
					internalNotificationsAdd(
						"You accepted a session request. It is now available in Dashboard's Today view.",
						"info"
					)
				);
			})
			.catch(error => {
				dispatch(sessionsError(connectionFilter(error)));
			});
	};
};

export const sessionsRejectItem = id => {
	return (dispatch, getState) => {
		dispatch(sessionsInitiate());

		const payload = {
			id: id,
			status: "REJECTED"
		};

		axios
			.getInstance()
			.patch(sessionsChangeStatusRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(sessionsReject(response.data));
				dispatch(sessionsSuccess());
				dispatch(sessionsFetchAll());
				dispatch(
					internalNotificationsAdd(
						"You rejected a session request. The user is notified accordingly.",
						"danger"
					)
				);
			})
			.catch(error => {
				dispatch(sessionsError(connectionFilter(error)));
			});
	};
};

export const sessionsCreateGroupSession = (
	start,
	end,
	date,
	training_id,
	capacity,
	remark = ""
) => {
	return (dispatch, getState) => {
		dispatch(sessionsInitiate());

		const payload = {
			start: start,
			end: end,
			date: date,
			training_id: training_id,
			capacity: capacity,
			remark: remark
		};

		axios
			.getInstance()
			.post(sessionsCreateGroupRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(() => {
				dispatch(sessionsSuccess());
				dispatch(sessionsFetchAll());
				dispatch(
					internalNotificationsAdd(
						"You just scheduled a new group session. Members will be able to join accordingly.",
						"success"
					)
				);
			})
			.catch(error => {
				dispatch(sessionsError(connectionFilter(error)));
			});
	};
};
