import {
	STATISTICS_FETCH,
	STATISTICS_WAITING,
	STATISTICS_SUCCESS,
	STATISTICS_ERROR
} from "../actions.js";

const initialState = {
	monthlySessions: [],
	popularWorkouts: [],
	sessionCount: 0,
	sessionsToday: 0,
	sessionsRescheduledToday: 0,
	sessionsFinishedToday: 0,
	userCount: 0,
	profitLastMonth: 0,
	profitTotal: 0,
	waiting: false,
	error: null
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case STATISTICS_FETCH:
			return {
				...state,
				monthlySessions: action.payload.monthlySessions
					? action.payload.monthlySessions.map(element => ({
							...element
					  }))
					: [],
				popularWorkouts: action.payload.popularWorkouts
					? action.payload.popularWorkouts.map(element => ({
							...element
					  }))
					: [],
				sessionCount: action.payload.sessionCount,
				userCount: action.payload.userCount,
				sessionsToday: action.payload.sessionsToday,
				sessionsRescheduledToday:
					action.payload.sessionsRescheduledToday,
				sessionsFinishedToday: action.payload.sessionsFinishedToday,
				profitLastMonth: action.payload.profitLastMonth,
				profitTotal: action.payload.profitTotal
			};
		case STATISTICS_SUCCESS:
			return {
				...state,
				waiting: false,
				error: null
			};
		case STATISTICS_WAITING:
			return {
				...state,
				waiting: true,
				error: null
			};
		case STATISTICS_ERROR:
			return {
				...state,
				error: action.payload.error,
				waiting: false
			};
		default:
			return state;
	}
};

export default reducer;
