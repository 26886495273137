import {
	EQUIPMENT_FETCH,
	EQUIPMENT_ADD,
	EQUIPMENT_REMOVE,
	EQUIPMENT_EDIT,
	EQUIPMENT_SELECT,
	EQUIPMENT_SUCCESS,
	EQUIPMENT_WAITING,
	EQUIPMENT_ERROR
} from "../actions.js";

const initialState = {
	equipment: [],
	selected: null,
	waiting: false,
	error: null
};

const reducer = (state = initialState, action) => {
	let array = state.equipment.map(element => ({ ...element }));
	switch (action.type) {
		case EQUIPMENT_FETCH:
			return {
				...state,
				equipment: action.payload.equipment.map(element => ({
					...element
				}))
			};
		case EQUIPMENT_ADD:
			array.push({ ...action.payload.equipmentItem });
			return {
				...state,
				equipment: array
			};
		case EQUIPMENT_REMOVE:
			return {
				...state,
				equipment: array.filter(
					element => element.id !== action.payload.id
				)
			};
		case EQUIPMENT_EDIT:
			return {
				...state,
				equipment: state.equipment.map(element =>
					element.id === action.payload.equipmentItem.id
						? { ...action.payload.equipmentItem }
						: { ...element }
				),
				selected: {
					...action.payload.equipmentItem
				}
			};
		case EQUIPMENT_SELECT:
			return {
				...state,
				selected:
					action.payload.id !== null
						? {
								...state.equipment.find(
									element => element.id === action.payload.id
								)
						  }
						: null
			};
		case EQUIPMENT_SUCCESS:
			return {
				...state,
				waiting: false,
				error: null
			};
		case EQUIPMENT_WAITING:
			return {
				...state,
				waiting: true,
				error: null
			};
		case EQUIPMENT_ERROR:
			return {
				...state,
				error: action.payload.error,
				waiting: false
			};
		default:
			return state;
	}
};

export default reducer;
