import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import isMonday from "date-fns/isMonday";
import isTuesday from "date-fns/isTuesday";
import isWednesday from "date-fns/isWednesday";
import isThursday from "date-fns/isThursday";
import isFriday from "date-fns/isFriday";
import isSaturday from "date-fns/isSaturday";
import isSunday from "date-fns/isSunday";
import isSameDay from "date-fns/isSameDay";

import { calendarBaseColors } from "./styles-base";

export const bridgeAgendaForWorkingDays = (workingDays, relevantMonth) => {
	let agenda = {};
	let currentDay = !relevantMonth ? new Date() : relevantMonth;
	let startMonth = startOfMonth(currentDay);
	let endMonth = endOfMonth(currentDay);
	let agendaObject = {
		type: "WORK_DAY",
		color: calendarBaseColors.blue
	};

	eachDayOfInterval({ start: startMonth, end: endMonth }).forEach(element => {
		if (workingDays.Monday && isMonday(element))
			agenda[format(element, "yyyy-MM-dd")] = Object.assign(
				{},
				agendaObject
			);
		else if (workingDays.Tuesday && isTuesday(element))
			agenda[format(element, "yyyy-MM-dd")] = Object.assign(
				{},
				agendaObject
			);
		else if (workingDays.Wednesday && isWednesday(element))
			agenda[format(element, "yyyy-MM-dd")] = Object.assign(
				{},
				agendaObject
			);
		else if (workingDays.Thursday && isThursday(element))
			agenda[format(element, "yyyy-MM-dd")] = Object.assign(
				{},
				agendaObject
			);
		else if (workingDays.Friday && isFriday(element))
			agenda[format(element, "yyyy-MM-dd")] = Object.assign(
				{},
				agendaObject
			);
		else if (workingDays.Saturday && isSaturday(element))
			agenda[format(element, "yyyy-MM-dd")] = Object.assign(
				{},
				agendaObject
			);
		else if (workingDays.Sunday && isSunday(element))
			agenda[format(element, "yyyy-MM-dd")] = Object.assign(
				{},
				agendaObject
			);
	});

	return agenda;
};

export const bridgeAgendaForExceptions = (
	exceptionDays,
	relevantMonth,
	type
) => {
	let agenda = {};
	let currentDay = !relevantMonth ? new Date() : relevantMonth;
	let startMonth = startOfMonth(currentDay);
	let endMonth = endOfMonth(currentDay);
	let agendaObject =
		type === "EXCEPTION"
			? { type: type, color: calendarBaseColors.red }
			: { type: type, color: calendarBaseColors.yellow };

	eachDayOfInterval({ start: startMonth, end: endMonth }).forEach(element => {
		for (let exceptionDay of exceptionDays) {
			if (
				isSameDay(
					element,
					parse(exceptionDay.date, "yyyy-MM-dd", new Date())
				)
			) {
				if (!agenda[exceptionDay.date])
					agenda[exceptionDay.date] = Object.assign({}, agendaObject);
				if (type === "EXCEPTION") {
					if (!agenda[exceptionDay.date].startTime) {
						agenda[exceptionDay.date].startTime =
							exceptionDay.start;
						agenda[exceptionDay.date].endTime = exceptionDay.end;
					} else {
						agenda[exceptionDay.date].additional
							? agenda[exceptionDay.date].additional.push({
									startTime: exceptionDay.start,
									endTime: exceptionDay.end,
									id: exceptionDay.id
							  })
							: (agenda[exceptionDay.date].additional = [
									{
										startTime: exceptionDay.start,
										endTime: exceptionDay.end,
										id: exceptionDay.id
									}
							  ]);
					}
				}
			}
		}
	});

	return agenda;
};

export const bridgeAgendaForSchedule = markedDates => {
	let agenda = {};
	let agendaObject = {
		type: "CUSTOM_DAY"
	};

	[...Object.keys(markedDates)].forEach(element => {
		agenda[element] = Object.assign({}, agendaObject);
		agenda[element].color = markedDates[element].textColor;
	});

	return agenda;
};
