import {
	INTERNAL_NOTIFICATIONS_ADD,
	INTERNAL_NOTIFICATIONS_REMOVE,
	INTERNAL_MAINTENANCE_ENABLE,
	INTERNAL_MAINTENANCE_DISABLE
} from "../actions.js";
import { authLogout } from "./auth.js";

const internalNotifiction = (message, variant, id) => {
	return {
		type: INTERNAL_NOTIFICATIONS_ADD,
		payload: {
			notificationItem: {
				message: message
					? message
					: "Something went wrong. If this happens again please let us know.",
				variant: variant,
				id: id
			}
		}
	};
};

export const internalNotificationsAdd = (message, variant, id) => {
	return dispatch => {
		const notificationId = id
			? id
			: +new Date() + Math.floor(Math.random() * 1001);
		dispatch(internalNotifiction(message, variant, notificationId));
		setTimeout(() => {
			dispatch(internalNotificationsRemove(notificationId));
		}, 10 * 1000);
	};
};

export const internalNotificationsRemove = id => {
	return {
		type: INTERNAL_NOTIFICATIONS_REMOVE,
		payload: {
			id: id
		}
	};
};

const internalMaintenance = () => {
	return {
		type: INTERNAL_MAINTENANCE_ENABLE
	};
};

const internalMaintenanceEnd = () => {
	return {
		type: INTERNAL_MAINTENANCE_DISABLE
	};
};

export const internalMaintenanceEnable = () => {
	return dispatch => {
		dispatch(authLogout());
		dispatch(internalMaintenance());
	};
};

export const internalMaintenanceDisable = () => {
	return dispatch => {
		dispatch(internalMaintenanceEnd());
	};
};
