import {
	AUTH_TRY,
	AUTH_INITIATE,
	AUTH_SUCCESS,
	AUTH_ERROR,
	AUTH_LOGOUT,
	AUTH_REDIRECT
} from "../actions.js";

const initialState = {
	authenticated: false,
	initial: false,
	waiting: false,
	error: null,
	redirect: "/"
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case AUTH_TRY:
			return {
				...state,
				initial: action.payload.initial
			};
		case AUTH_INITIATE:
			return {
				...state,
				waiting: true,
				error: null
			};
		case AUTH_SUCCESS:
			return {
				...state,
				authenticated: true,
				waiting: false,
				initial: false,
				error: null
			};
		case AUTH_ERROR:
			return {
				...state,
				authenticated: false,
				waiting: false,
				initial: false,
				error: action.payload.error
			};
		case AUTH_LOGOUT:
			return {
				...state,
				authenticated: false,
				waiting: false,
				initial: false,
				error: null
			};
		case AUTH_REDIRECT:
			return {
				...state,
				redirect: action.payload.redirect
			};
		default:
			return state;
	}
};

export default reducer;
