import {
	NOTIFICATIONS_FETCH,
	NOTIFICATIONS_PUSH_INIT,
	NOTIFICATIONS_PUSH_EXIT,
	NOTIFICATIONS_ADD,
	NOTIFICATIONS_SUCCESS,
	NOTIFICATIONS_WAITING,
	NOTIFICATIONS_ERROR
} from "../actions.js";
import axios from "../../utils/functions/axios-instance";
import sortBy from "lodash/sortBy";

import {
	notificationsGetRoute,
	notificationsPushAddRoute
} from "../../utils/constants/constants-api";
import { internalNotificationsAdd } from "./index.js";
import { connectionFilter } from "../../utils/functions/filtering-functions";

const notificationsSuccess = () => {
	return {
		type: NOTIFICATIONS_SUCCESS
	};
};

const notificationsInitiate = () => {
	return {
		type: NOTIFICATIONS_WAITING
	};
};

const notificationsError = error => {
	return {
		type: NOTIFICATIONS_ERROR,
		payload: {
			error: error
		}
	};
};

const notificationsFetch = notifications => {
	return {
		type: NOTIFICATIONS_FETCH,
		payload: {
			notifications: notifications
		}
	};
};

const notificationsAdd = notificationItem => {
	return {
		type: NOTIFICATIONS_ADD,
		payload: {
			notificationItem: notificationItem
		}
	};
};

export const notificationsPushInit = receivers => {
	return {
		type: NOTIFICATIONS_PUSH_INIT,
		payload: {
			receivers: receivers
		}
	};
};

export const notificationsPushExit = () => {
	return {
		type: NOTIFICATIONS_PUSH_EXIT
	};
};

export const notificationsFetchAll = () => {
	return (dispatch, getState) => {
		dispatch(notificationsInitiate());

		axios
			.getInstance()
			.get(notificationsGetRoute, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				let notifications = response.data.sort(function compare(a, b) {
					let firstDate = new Date(a.created);
					let secondDate = new Date(b.created);
					return secondDate - firstDate;
				});
				notifications = sortBy(notifications, o => o.seen);
				dispatch(notificationsFetch(notifications));
				dispatch(notificationsSuccess());
			})
			.catch(error => {
				dispatch(notificationsError(connectionFilter(error)));
			});
	};
};

export const notificationsAddItem = (name, description) => {
	return (dispatch, getState) => {
		dispatch(notificationsInitiate());

		const payload = {
			title: name,
			message: description
		};

		axios
			.getInstance()
			.post(notificationsPushAddRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(notificationsAdd({ ...response.data }));
				dispatch(notificationsSuccess());
				dispatch(
					internalNotificationsAdd(
						"You just sent a push notification to all your users.",
						"success"
					)
				);
			})
			.catch(error => {
				dispatch(notificationsError(connectionFilter(error)));
			});
	};
};
