import {
	SETTINGS_WAITING,
	SETTINGS_SUCCESS,
	SETTINGS_FETCH,
	SETTINGS_ERROR,
	SETTINGS_CHANGE_EMAIL,
	SETTINGS_CHANGE_PASSWORD,
	SETTINGS_CHANGE_COMPANY_NAME,
	SETTINGS_CHANGE_COMPANY_ADDRESS,
	SETTINGS_CHANGE_COMPANY_PHOTO,
	SETTINGS_CHANGE_NOTIFICATIONS_EXCEPTIONS,
	SETTINGS_ADD_PACK,
	SETTINGS_ENABLE_PACK,
	SETTINGS_DISABLE_PACK,
	SETTINGS_ADD_MEMBERSHIP,
	SETTINGS_ENABLE_MEMBERSHIP,
	SETTINGS_DISABLE_MEMBERSHIP,
	SETTINGS_ADD_PROMOTION,
	SETTINGS_REMOVE_PROMOTION,
	SETTINGS_ACTIVATE_PROMOTION,
	SETTINGS_DISABLE_PROMOTION
} from "../actions.js";
import {
	WORKOUT_INDIVIDUAL,
	WORKOUT_GROUP,
	WORKOUT_MEMBERSHIP
} from "../../utils/constants/constants.js";

const initialState = {
	account: {
		name: "",
		lastName: "",
		email: ""
	},
	company: {
		registrationNumber: "",
		name: "",
		address: {
			street: "",
			city: "",
			country: ""
		},
		photo: null
	},
	payment: {
		packs: [],
		memberships: [],
		promotions: []
	},
	notifications: {
		dispatchForExceptions: false
	},
	waiting: false,
	error: null
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SETTINGS_FETCH:
			return {
				...state,
				account: {
					name: action.payload.settings.account.first_name,
					lastName: action.payload.settings.account.last_name,
					email: action.payload.settings.account.email
				},
				company: {
					name: action.payload.settings.company.name,
					registrationNumber:
						action.payload.settings.company.registrationNumber,
					photo: action.payload.settings.company.photo,
					address: { ...action.payload.settings.company.address }
				},
				payment: {
					packs: action.payload.settings.payment.subscriptions
						.filter(
							element =>
								element.training_type === WORKOUT_INDIVIDUAL ||
								element.training_type === WORKOUT_GROUP
						)
						.sort(x => (x.active ? -1 : 1)),
					memberships: action.payload.settings.payment.subscriptions
						.filter(
							element =>
								element.training_type === WORKOUT_MEMBERSHIP
						)
						.sort(x => (x.active ? -1 : 1)),
					promotions: [
						{
							id: 1,
							name: "Black Friday Discount",
							price: 9.99,
							type: "PACK",
							training_type: "INDIVIDUAL_WORKOUT",
							training_amount: 10,
							start_date: "20/11/2019",
							end_date: "10/12/2019"
						}
					]
				},
				notifications: {
					dispatchForExceptions: action.payload.settings.notifications
				}
			};
		case SETTINGS_CHANGE_EMAIL:
			return {
				...state,
				account: {
					...state.account,
					email: action.payload.email
				}
			};
		case SETTINGS_CHANGE_PASSWORD:
			return {
				...state
			};
		case SETTINGS_CHANGE_COMPANY_NAME:
			return {
				...state,
				company: {
					...state.company,
					name: action.payload.name
				}
			};
		case SETTINGS_CHANGE_COMPANY_ADDRESS: {
			return {
				...state,
				company: {
					...state.company,
					address: { ...action.payload.address }
				}
			};
		}
		case SETTINGS_CHANGE_COMPANY_PHOTO:
			return {
				...state,
				company: {
					...state.company,
					photo: action.payload.photo
				}
			};
		case SETTINGS_CHANGE_NOTIFICATIONS_EXCEPTIONS:
			return {
				...state,
				notifications: {
					...state.notifications,
					dispatchForExceptions: action.payload.dispatchForExceptions
				}
			};
		case SETTINGS_ADD_PACK: {
			const packs = state.payment.packs.map(element => ({
				...element
			}));
			packs.push({ ...action.payload.packItem });
			return {
				...state,
				payment: {
					...state.payment,
					packs: packs.sort(x => (x.active ? -1 : 1))
				}
			};
		}
		case SETTINGS_ADD_MEMBERSHIP: {
			const memberships = state.payment.memberships.map(element => ({
				...element
			}));
			memberships.push({ ...action.payload.membershipItem });
			return {
				...state,
				payment: {
					...state.payment,
					memberships: memberships.sort(x => (x.active ? -1 : 1))
				}
			};
		}
		case SETTINGS_ADD_PROMOTION: {
			const promotions = state.payment.promotions.map(element => ({
				...element
			}));
			promotions.push({ ...action.payload.promotionItem });
			return {
				...state,
				payment: {
					...state.payment,
					promotions: promotions.sort(x => (x.active ? -1 : 1))
				}
			};
		}
		case SETTINGS_REMOVE_PROMOTION: {
			const promotions = state.payment.promotions
				.map(element => ({
					...element
				}))
				.filter(element => element.id !== action.payload.id);
			return {
				...state,
				payment: {
					...state.payment,
					promotions: promotions.sort(x => (x.active ? -1 : 1))
				}
			};
		}
		case SETTINGS_ACTIVATE_PROMOTION: {
			const promotions = state.payment.promotions.map(element =>
				element.id !== action.payload.id
					? { ...element }
					: { ...element, active: true }
			);
			return {
				...state,
				payment: {
					...state.payment,
					promotions: promotions.sort(x => (x.active ? -1 : 1))
				}
			};
		}
		case SETTINGS_DISABLE_PROMOTION: {
			const promotions = state.payment.promotions.map(element =>
				element.id !== action.payload.id
					? { ...element }
					: { ...element, active: false }
			);
			return {
				...state,
				payment: {
					...state.payment,
					promotions: promotions.sort(x => (x.active ? -1 : 1))
				}
			};
		}
		case SETTINGS_ENABLE_PACK: {
			const packs = state.payment.packs.map(element =>
				element.id !== action.payload.id
					? { ...element }
					: { ...element, active: true }
			);
			return {
				...state,
				payment: {
					...state.payment,
					packs: packs.sort(x => (x.active ? -1 : 1))
				}
			};
		}
		case SETTINGS_DISABLE_PACK: {
			const packs = state.payment.packs.map(element =>
				element.id !== action.payload.id
					? { ...element }
					: { ...element, active: false }
			);
			return {
				...state,
				payment: {
					...state.payment,
					packs: packs.sort(x => (x.active ? -1 : 1))
				}
			};
		}
		case SETTINGS_ENABLE_MEMBERSHIP: {
			const memberships = state.payment.memberships.map(element =>
				element.id !== action.payload.id
					? { ...element }
					: { ...element, active: true }
			);
			return {
				...state,
				payment: {
					...state.payment,
					memberships: memberships.sort(x => (x.active ? -1 : 1))
				}
			};
		}
		case SETTINGS_DISABLE_MEMBERSHIP: {
			const memberships = state.payment.memberships.map(element =>
				element.id !== action.payload.id
					? { ...element }
					: { ...element, active: false }
			);
			return {
				...state,
				payment: {
					...state.payment,
					memberships: memberships.sort(x => (x.active ? -1 : 1))
				}
			};
		}
		case SETTINGS_WAITING:
			return {
				...state,
				waiting: true,
				error: null
			};
		case SETTINGS_SUCCESS:
			return {
				...state,
				waiting: false,
				error: null
			};
		case SETTINGS_ERROR:
			return {
				...state,
				waiting: false,
				error: action.payload.error
			};
		default:
			return state;
	}
};

export default reducer;
