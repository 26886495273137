import {
	AVAILABILITY_FETCH,
	AVAILABILITY_SUCCESS,
	AVAILABILITY_WAITING,
	AVAILABILITY_ERROR
} from "../actions.js";
import axios from "../../utils/functions/axios-instance";

import { formatDayToCalendarMarkedDate } from "../../utils/functions/formatting-functions";
import { schedulesGetMonthlyScheduledRoute } from "../../utils/constants/constants-api";
import { connectionFilter } from "../../utils/functions/filtering-functions";

const availabilitySuccess = () => {
	return {
		type: AVAILABILITY_SUCCESS
	};
};

const availabilityInitiate = () => {
	return {
		type: AVAILABILITY_WAITING
	};
};

const availabilityError = error => {
	return {
		type: AVAILABILITY_ERROR,
		payload: {
			error: error
		}
	};
};

const availabilityFetch = markedDates => {
	return {
		type: AVAILABILITY_FETCH,
		payload: {
			markedDates: markedDates
		}
	};
};

export const availabilityFetchAll = (month, year, training_id) => {
	return (dispatch, getState) => {
		dispatch(availabilityInitiate());

		axios
			.getInstance()
			.get(schedulesGetMonthlyScheduledRoute, {
				headers: { Authorization: getState().user.token },
				params: {
					month: month + 1,
					year: year,
					training_id: training_id
				}
			})
			.then(response => {
				let monthAvailabilityData = {};
				for (let date of response.data)
					monthAvailabilityData[
						date.day.toString()
					] = formatDayToCalendarMarkedDate(date);
				dispatch(availabilityFetch(monthAvailabilityData));
				dispatch(availabilitySuccess());
			})
			.catch(error => {
				dispatch(availabilityError(connectionFilter(error)));
			});
	};
};
