import {
	WORKOUTS_FETCH,
	WORKOUTS_ADD,
	WORKOUTS_REMOVE,
	WORKOUTS_EDIT,
	WORKOUTS_SELECT,
	WORKOUTS_SUCCESS,
	WORKOUTS_WAITING,
	WORKOUTS_ERROR
} from "../actions.js";

import axios from "../../utils/functions/axios-instance";

import {
	trainingsCreateTrainingRoute,
	trainingsGetTrainingsRoute,
	trainingsUpdateTrainingRoute,
	trainingsDeleteTrainingRoute,
	videosGetVideoRoute
} from "../../utils/constants/constants-api";
import { connectionFilter } from "../../utils/functions/filtering-functions";

const getIntensity = string => {
	if (string === "Low intensity") return 0;
	else if (string === "Medium intensity") return 1;
	else return 2;
};

const getDifficulty = string => {
	if (string === "Beginner") return 0;
	else if (string === "Intermediate") return 1;
	else return 2;
};

const workoutsSuccess = () => {
	return {
		type: WORKOUTS_SUCCESS
	};
};

const workoutsInitiate = () => {
	return {
		type: WORKOUTS_WAITING
	};
};

const workoutsError = error => {
	return {
		type: WORKOUTS_ERROR,
		payload: {
			error: error
		}
	};
};

const workoutsFetch = workouts => {
	return {
		type: WORKOUTS_FETCH,
		payload: {
			workouts: workouts
		}
	};
};

const workoutsAdd = workoutItem => {
	return {
		type: WORKOUTS_ADD,
		payload: {
			workoutItem: workoutItem
		}
	};
};

const workoutsRemove = id => {
	return {
		type: WORKOUTS_REMOVE,
		payload: {
			id: id
		}
	};
};

const workoutsEdit = workoutItem => {
	return {
		type: WORKOUTS_EDIT,
		payload: {
			workoutItem: workoutItem
		}
	};
};

const workoutsSelect = (id, video) => {
	return {
		type: WORKOUTS_SELECT,
		payload: {
			id: id,
			video: video
		}
	};
};

export const workoutsSelectItem = id => {
	return (dispatch, getState) => {
		dispatch(workoutsInitiate());

		if (id) {
			let workout = null;
			getState().workouts.workouts.forEach(element => {
				if (element.id === id) workout = element;
			});

			return axios
				.getInstance()
				.get(videosGetVideoRoute, {
					headers: { Authorization: getState().user.token },
					params: { id: workout && workout.video_id }
				})
				.then(response => {
					dispatch(workoutsSelect(id, { ...response.data }));
					dispatch(workoutsSuccess());
				})
				.catch(error => {
					dispatch(workoutsError(connectionFilter(error)));
				});
		} else {
			dispatch(workoutsSelect(null));
		}
	};
};

export const workoutsFetchAll = () => {
	return (dispatch, getState) => {
		dispatch(workoutsInitiate());

		axios
			.getInstance()
			.get(trainingsGetTrainingsRoute, {
				"Content-Type": "application/x-www-form-urlencoded",
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(workoutsFetch(response.data));
				dispatch(workoutsSuccess());
			})
			.catch(error => {
				dispatch(workoutsError(connectionFilter(error)));
			});
	};
};

export const workoutsAddItem = (
	title,
	description,
	duration,
	intensity,
	level,
	thumbnail,
	videoId,
	equipmentIds,
	type
) => {
	return (dispatch, getState) => {
		dispatch(workoutsInitiate());

		const payload = new FormData();
		payload.append("title", title);
		payload.append("description", description);
		payload.append("type", type);
		payload.append("duration", duration);
		payload.append("intensity", getIntensity(intensity));
		payload.append("level", getDifficulty(level));
		if (thumbnail) payload.append("source", thumbnail, thumbnail.name);
		payload.append("video_id", videoId);
		payload.append("equipment_id", JSON.stringify(equipmentIds));

		axios
			.getInstance()
			.post(trainingsCreateTrainingRoute, payload, {
				"Content-Type": "multipart/form-data",
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(workoutsAdd({ ...response.data }));
				dispatch(workoutsSuccess());
			})
			.catch(error => {
				dispatch(workoutsError(connectionFilter(error)));
			});
	};
};

export const workoutsEditItem = (
	id,
	title,
	description,
	duration,
	intensity,
	level,
	thumbnail,
	videoId,
	equipmentIds,
	type
) => {
	return (dispatch, getState) => {
		dispatch(workoutsInitiate());

		const payload = new FormData();
		payload.append("_method", "PATCH");
		payload.append("id", id);
		payload.append("title", title);
		payload.append("description", description);
		payload.append("type", type);
		payload.append("duration", duration);
		payload.append("intensity", getIntensity(intensity));
		payload.append("level", getDifficulty(level));
		if (thumbnail) payload.append("source", thumbnail, thumbnail.name);
		payload.append("equipment_id", JSON.stringify(equipmentIds));
		payload.append("video_id", videoId);

		axios
			.getInstance()
			.post(trainingsUpdateTrainingRoute, payload, {
				"Content-Type": "application/x-www-form-urlencoded",
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(workoutsEdit({ ...response.data }));
				dispatch(workoutsSelect(null));
				dispatch(workoutsSuccess());
			})
			.catch(error => {
				dispatch(workoutsError(connectionFilter(error)));
			});
	};
};

export const workoutsRemoveItem = id => {
	return (dispatch, getState) => {
		dispatch(workoutsInitiate());

		const payload = {
			id: id
		};

		axios
			.getInstance()
			.delete(trainingsDeleteTrainingRoute, {
				headers: {
					Authorization: getState().user.token
				},
				data: payload
			})
			.then(() => {
				dispatch(workoutsRemove(id));
				dispatch(workoutsSelect(null));
				dispatch(workoutsSuccess());
			})
			.catch(error => {
				dispatch(workoutsError(connectionFilter(error)));
			});
	};
};
