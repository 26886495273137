import {
	PAYMENTS_FETCH,
	PAYMENTS_FETCH_PENDING,
	PAYMENTS_FETCH_LOCAL_PENDING,
	PAYMENTS_FETCH_MONTHLY_REPORT,
	PAYMENTS_SELECT,
	PAYMENTS_EDIT,
	PAYMENTS_WAITING,
	PAYMENTS_SUCCESS,
	PAYMENTS_ERROR,
	PAYMENTS_CHANGE_STATUS
} from "../actions.js";

const initialState = {
	payments: [],
	paymentsPending: [],
	localPaymentsPending: [],
	paymentsMonthlyReport: {
		earnings: 0,
		customersByEarnings: []
	},
	selected: null,
	waiting: false,
	error: null
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case PAYMENTS_FETCH:
			return {
				...state,
				payments: action.payload.payments.map(element => ({
					...element
				}))
			};
		case PAYMENTS_FETCH_PENDING:
			return {
				...state,
				paymentsPending: action.payload.payments.map(element => ({
					...element
				}))
			};
		case PAYMENTS_FETCH_LOCAL_PENDING:
			return {
				...state,
				localPaymentsPending: action.payload.payments.map(element => ({
					...element
				}))
			};
		case PAYMENTS_FETCH_MONTHLY_REPORT:
			return {
				...state,
				paymentsMonthlyReport: {
					earnings: action.payload.earnings,
					customersByEarnings: action.payload.customersByEarnings.map(
						element => ({
							...element
						})
					)
				}
			};
		case PAYMENTS_EDIT: {
			let arrayPayments = state.payments.map(element => ({ ...element }));
			let arrayPaymentsPending = state.paymentsPending.map(element => ({
				...element
			}));
			arrayPaymentsPending.push(action.payload.paymentItem);
			return {
				...state,
				payments: arrayPayments.filter(
					element => element.id !== action.payload.paymentItem.id
				),
				paymentsPending: arrayPaymentsPending
			};
		}
		case PAYMENTS_SELECT:
			return {
				...state,
				selected:
					action.payload.id !== null
						? {
								...state.payments.find(
									element => element.id === action.payload.id
								)
						  }
						: null
			};
		case PAYMENTS_CHANGE_STATUS:
			return {
				...state,
				localPaymentsPending: state.localPaymentsPending
					.map(element => ({ ...element }))
					.filter(element => element.id !== action.payload.id)
			};
		case PAYMENTS_SUCCESS:
			return {
				...state,
				waiting: false,
				error: null
			};
		case PAYMENTS_WAITING:
			return {
				...state,
				waiting: true,
				error: null
			};
		case PAYMENTS_ERROR:
			return {
				...state,
				error: action.payload.error,
				waiting: false
			};
		default:
			return state;
	}
};

export default reducer;
