import React from "react";
import AppFrame from "./containers/frame/frame";
import BoundaryError from "./components/interface/boundary-error/boundaryError";
import BoundaryLoading from "./components/interface/boundary-loading/boundaryLoading";
import BoundaryMaintenance from "./components/interface/boundary-maintenance/boundaryMaintenance";
import NotificationHandler from "./components/compound/notification-handler/notificationHandler";
import PropTypes from "prop-types";

import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
	authenticatePersisted,
	authenticatePersistedInit,
	userNotificationIDsAdd
} from "./store/actions/index.js";

import withMaterial from "./hoc/withMaterial/withMaterial";
import withWebPush from "./hoc/withWebPush/withWebPush";
import withMultilingual from "./hoc/withMultilingual/withMultilingual";

const Auth = React.lazy(() => import("./containers/auth/auth"));
const Home = React.lazy(() => import("./containers/home/home"));
const Media = React.lazy(() => import("./containers/media/media"));
const Equipment = React.lazy(() => import("./containers/equipment/equipment"));
const Videos = React.lazy(() => import("./containers/videos/videos"));
const Calendar = React.lazy(() => import("./containers/calendar/calendar"));
const Schedule = React.lazy(() => import("./containers/schedule/schedule"));
const Dashboard = React.lazy(() => import("./containers/dashboard/dashboard"));
const Settings = React.lazy(() => import("./containers/settings/settings"));
const About = React.lazy(() => import("./containers/about/about"));
const AboutPricing = React.lazy(() => import("./containers/about/about-pricing/aboutPricing"));
const AboutDemo = React.lazy(() => import("./containers/about/about-demo/aboutDemo"));
const AboutSupport = React.lazy(() => import("./containers/about/about-support/aboutSupport"));
const AboutPrivacy = React.lazy(() => import("./containers/about/about-privacy/aboutPrivacy"));
const AboutFitspriteNutritionPrivacy = React.lazy(() => import("./containers/about/about-fitspritenutrition-privacy/aboutFitspritenutritionPrivacy.js"))

class App extends React.Component {
	constructor(props) {
		super(props);
		props.authenticatePersistedInit();
	}

	componentDidMount() {
		this.props.authenticatePersisted();
	}

	render() {
		return (
			<BoundaryError>
				<AppFrame maintenance={this.props.maintenance}>
					<NotificationHandler />
					{this.props.maintenance ? (
						<BoundaryMaintenance />
					) : (
						<React.Fragment>
							{!this.props.authenticated &&
								!this.props.initial ? (
								<React.Suspense fallback={<BoundaryLoading />}>
									<Switch>
										<Route path="/pricing" exact>
											<AboutPricing />
										</Route>
										<Route path="/support" exact>
											<AboutSupport />
										</Route>
										<Route path="/fitspritenutrition/pptc/" exact>
											<AboutFitspriteNutritionPrivacy />
										</Route>
										<Route path="/privacy" exact>
											<AboutPrivacy />
										</Route>
										<Route path="/demo" exact>
											<AboutDemo />
										</Route>
										<Route path="/auth">
											<Auth />
										</Route>
										<Route path="/" exact>
											<About />
										</Route>
										<Redirect to="/" />
									</Switch>
								</React.Suspense>
							) : this.props.authenticated ? (
								<React.Suspense fallback={<BoundaryLoading />}>
									<Switch>
										<Route path="/schedule">
											<Schedule />
										</Route>
										<Route path="/calendar">
											<Calendar />
										</Route>
										<Route path="/equipment">
											<Equipment />
										</Route>
										<Route path="/videos">
											<Videos />
										</Route>
										<Route path="/media">
											<Media />
										</Route>
										<Route path="/dashboard">
											<Dashboard />
										</Route>
										<Route path="/settings">
											<Settings />
										</Route>
										<Route path="/" exact>
											<Home />
										</Route>
										<Redirect to="/" />
									</Switch>
								</React.Suspense>
							) : (
								<BoundaryLoading />
							)}
						</React.Fragment>
					)}
				</AppFrame>
			</BoundaryError>
		);
	}
}

const mapStateToProps = state => {
	return {
		initial: state.auth.initial,
		authenticated: state.auth.authenticated,
		data: state.user.data,
		maintenance: state.internal.maintenance
	};
};

const mapDispatchToProps = dispatch => {
	return {
		authenticatePersisted: () => dispatch(authenticatePersisted()),
		authenticatePersistedInit: () => dispatch(authenticatePersistedInit()),
		userNotificationIDsAdd: id => dispatch(userNotificationIDsAdd(id))
	};
};

App.propTypes = {
	initial: PropTypes.bool.isRequired,
	authenticated: PropTypes.bool.isRequired,
	authenticatePersisted: PropTypes.func.isRequired,
	authenticatePersistedInit: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	maintenance: PropTypes.bool.isRequired
};

export default withMaterial(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withWebPush(withMultilingual(App)))
);
