import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

import { styles } from "./stylesheet";
import withStyles from "@material-ui/core/styles/withStyles";

import LinearProgress from "@material-ui/core/LinearProgress";

const boundaryMaintenance = props => {
	const { classes } = props;

	return (
		<Grid
			container
			direction="column"
			align="center"
			spacing={6}
			className={classes.root}
		>
			<Grid item>
				<LinearProgress variant="query" color="primary" />
			</Grid>
			<Grid item>
				<Typography
					variant="h5"
					className={classes.lighter}
					gutterBottom
				>
					We are under maintenance!
				</Typography>
				<Typography
					variant="h6"
					className={classes.lighter}
					color="textSecondary"
				>
					Please check back in about an hour. Everything should be
					back to normal then.
				</Typography>
			</Grid>
		</Grid>
	);
};

boundaryMaintenance.propTypes = {
	classes: PropTypes.object.isRequired
};

export default React.memo(withStyles(styles)(boundaryMaintenance));
