import {
	shallowShadow,
	transition,
	transitionFluid
} from "../../../assets/stylesheet";

export const styles = theme => ({
	popperClose: {
		pointerEvents: "none"
	},
	popperResponsive: {
		zIndex: "1200",
		maxHeight: "80%",
		overflowY: "auto",
		...shallowShadow
	},
	dropdown: {
		position: "realtive",
		zIndex: "1201",
		height: "100%",
		borderRadius: theme.spacing(0.5),
		width: "35rem",
		padding: theme.spacing(1, 0),
		textAlign: "left",
		listStyle: "none",
		backgroundColor: "#fff",
		[theme.breakpoints.down("sm")]: {
			width: "80vw"
		}
	},
	menuList: {
		padding: 0
	},
	dropdownItem: {
		padding: theme.spacing(2),
		margin: theme.spacing(0, 1),
		...transition,
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start"
	},
	dropdownRow: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center"
	},
	dropdownIcon: {
		marginRight: theme.spacing(2)
	},
	dropdownDividerItem: {
		margin: theme.spacing(0.5, 0),
		backgroundColor: "rgba(0, 0, 0, 0.12)",
		height: "1px"
	},
	buttonIcon: {
		color: "white",
		padding: 0,
		margin: 0
	},
	buttonText: {
		minWidth: 0,
		color: "white",
		padding: theme.spacing(1.75),
		borderRadius: "50%"
	},
	dropdownHeader: {
		padding: theme.spacing(2),
		margin: theme.spacing(0, 1),
		...transitionFluid
	},
	label: {
		width: "100%",
		height: "auto",
		overflowWrap: "break-word",
		whiteSpace: "normal"
	}
});
