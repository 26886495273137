export const WORKOUT_TYPE_INDIVIDUAL = "INDIVIDUAL_WORKOUT";
export const WORKOUT_TYPE_GROUP = "GROUP_WORKOUT";

export const WORKOUT_GROUP = "GROUP_WORKOUT";
export const WORKOUT_INDIVIDUAL = "INDIVIDUAL_WORKOUT";
export const WORKOUT_MEMBERSHIP = "BOTH_WORKOUT";
export const WORKOUT_MAXINT = 2147483647;

export const SUBSCRIPTION_MEMEBRSHIP = "MEMBERSHIP";
export const SUBSCRIPTION_PACK = "PACK";

export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_USER = "ROLE_USER";
