import {
	SCHEDULE_FETCH_WORKING_DAYS,
	SCHEDULE_FETCH_EXCEPTIONS,
	SCHEDULE_FETCH_RECURRING_EXCEPTIONS,
	SCHEDULE_FETCH_HOLIDAYS,
	SCHEDULE_SET_WORKING_DAYS,
	SCHEDULE_SET_EXCEPTIONS,
	SCHEDULE_SET_RECURRING_EXCEPTIONS,
	SCHEDULE_SET_HOLIDAYS,
	SCHEDULE_CHANGE_MONTH,
	SCHEDULE_REMOVE_RECURRING_EXCEPTION,
	SCHEDULE_REMOVE_EXCEPTION,
	SCHEDULE_REMOVE_HOLIDAY,
	SCHEDULE_SUCCESS,
	SCHEDULE_WAITING,
	SCHEDULE_ERROR
} from "../actions.js";
import axios from "../../utils/functions/axios-instance";

import {
	workHoursCreateExceptionsRoute,
	workHoursCreateRecurringExceptionsRoute,
	workHoursCreateHolidayRoute,
	workHoursCreateWorkingDaysRoute,
	workHoursGetExceptionsRoute,
	workHoursGetRecurringExceptionsRoute,
	workHoursRemoveRecurringExceptionRoute,
	workHoursRemoveExceptionRoute,
	workHoursRemoveHolidayRoute,
	workHoursGetHolidaysRoute,
	workHoursGetWorkingDaysRoute
} from "../../utils/constants/constants-api";
import { internalNotificationsAdd } from "./index";
import { connectionFilter } from "../../utils/functions/filtering-functions";

const scheduleSuccess = () => {
	return {
		type: SCHEDULE_SUCCESS
	};
};

const scheduleInitiate = () => {
	return {
		type: SCHEDULE_WAITING
	};
};

const scheduleError = error => {
	return {
		type: SCHEDULE_ERROR,
		payload: {
			error: error
		}
	};
};

const scheduleFetchWorkingDays = workingDays => {
	return {
		type: SCHEDULE_FETCH_WORKING_DAYS,
		payload: {
			workingDays: workingDays
		}
	};
};

const scheduleFetchExceptions = exceptions => {
	return {
		type: SCHEDULE_FETCH_EXCEPTIONS,
		payload: {
			exceptions: exceptions
		}
	};
};

const scheduleFetchRecurringExceptions = recurringExceptions => {
	return {
		type: SCHEDULE_FETCH_RECURRING_EXCEPTIONS,
		payload: {
			recurringExceptions: recurringExceptions
		}
	};
};

const scheduleRemoveRecurringException = id => {
	return {
		type: SCHEDULE_REMOVE_RECURRING_EXCEPTION,
		payload: {
			id: id
		}
	};
};

const scheduleRemoveException = ids => {
	return {
		type: SCHEDULE_REMOVE_EXCEPTION,
		payload: {
			ids: ids
		}
	};
};

const scheduleRemoveHoliday = ids => {
	return {
		type: SCHEDULE_REMOVE_HOLIDAY,
		payload: {
			ids: ids
		}
	};
};

const scheduleFetchHolidays = holidays => {
	return {
		type: SCHEDULE_FETCH_HOLIDAYS,
		payload: {
			holidays: holidays
		}
	};
};

const setWorkingDays = workingDays => {
	return {
		type: SCHEDULE_SET_WORKING_DAYS,
		payload: {
			workingDays: workingDays
		}
	};
};

const setHolidays = holidays => {
	return {
		type: SCHEDULE_SET_HOLIDAYS,
		payload: {
			holidays: holidays
		}
	};
};

const setExceptions = exceptions => {
	return {
		type: SCHEDULE_SET_EXCEPTIONS,
		payload: {
			exceptions: exceptions
		}
	};
};

const setRecurringExceptions = recurringExceptions => {
	return {
		type: SCHEDULE_SET_RECURRING_EXCEPTIONS,
		payload: {
			recurringExceptions: recurringExceptions
		}
	};
};

export const scheduleChangeMonth = month => {
	return {
		type: SCHEDULE_CHANGE_MONTH,
		payload: {
			month: month
		}
	};
};

export const scheduleFetchWorkingDaysAll = () => {
	return (dispatch, getState) => {
		dispatch(scheduleInitiate());

		axios
			.getInstance()
			.get(workHoursGetWorkingDaysRoute, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(scheduleFetchWorkingDays(response.data));
				dispatch(scheduleSuccess());
			})
			.catch(error => {
				dispatch(scheduleError(connectionFilter(error)));
			});
	};
};

export const scheduleFetchExceptionsAll = () => {
	return (dispatch, getState) => {
		dispatch(scheduleInitiate());

		axios
			.getInstance()
			.get(workHoursGetExceptionsRoute, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(scheduleFetchExceptions(response.data));
				dispatch(scheduleSuccess());
			})
			.catch(error => {
				dispatch(scheduleError(connectionFilter(error)));
			});
	};
};

export const scheduleFetchRecurringExceptionsAll = () => {
	return (dispatch, getState) => {
		dispatch(scheduleInitiate());

		axios
			.getInstance()
			.get(workHoursGetRecurringExceptionsRoute, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(scheduleFetchRecurringExceptions(response.data));
				dispatch(scheduleSuccess());
			})
			.catch(error => {
				dispatch(scheduleError(connectionFilter(error)));
			});
	};
};

export const scheduleFetchHolidaysAll = () => {
	return (dispatch, getState) => {
		dispatch(scheduleInitiate());

		axios
			.getInstance()
			.get(workHoursGetHolidaysRoute, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(scheduleFetchHolidays(response.data));
				dispatch(scheduleSuccess());
			})
			.catch(error => {
				dispatch(scheduleError(connectionFilter(error)));
			});
	};
};

export const scheduleSetWorkingDays = (workingDays, start, end) => {
	return (dispatch, getState) => {
		dispatch(scheduleInitiate());

		const payload = {
			days: workingDays,
			start: start,
			end: end
		};

		axios
			.getInstance()
			.post(workHoursCreateWorkingDaysRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(setWorkingDays(response.data));
				dispatch(scheduleSuccess());
				dispatch(
					internalNotificationsAdd(
						"You successfully changed your working days!",
						"success"
					)
				);
			})
			.catch(error => {
				dispatch(scheduleError(connectionFilter(error)));
			});
	};
};

export const scheduleSetExceptions = (exceptions, start, end) => {
	return (dispatch, getState) => {
		dispatch(scheduleInitiate());

		const payload = {
			exceptions: []
		};
		Object.keys(exceptions).forEach(element =>
			payload.exceptions.push({
				date: element,
				start: start,
				end: end
			})
		);

		axios
			.getInstance()
			.post(workHoursCreateExceptionsRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(setExceptions(response.data));
				dispatch(
					internalNotificationsAdd(
						"You successfully added new exceptions, check them in the calendar!",
						"success"
					)
				);
				dispatch(scheduleSuccess());
			})
			.catch(error => {
				dispatch(scheduleError(connectionFilter(error)));
			});
	};
};

export const scheduleSetRecurringExceptions = (day, start, end) => {
	return (dispatch, getState) => {
		dispatch(scheduleInitiate());

		const payload = {
			day: day,
			start: start,
			end: end
		};

		axios
			.getInstance()
			.post(workHoursCreateRecurringExceptionsRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(setRecurringExceptions(response.data));
				dispatch(
					internalNotificationsAdd(
						"You successfully added a new recurring exception, check them in the list below!",
						"success"
					)
				);
				dispatch(scheduleSuccess());
			})
			.catch(error => {
				dispatch(scheduleError(connectionFilter(error)));
			});
	};
};

export const scheduleSetHolidays = holidays => {
	return (dispatch, getState) => {
		dispatch(scheduleInitiate());

		const payload = {
			holidays: Object.keys(holidays)
		};

		axios
			.getInstance()
			.post(workHoursCreateHolidayRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(setHolidays(response.data));
				dispatch(
					internalNotificationsAdd(
						"You successfully added new holidays, check them in the calendar!",
						"success"
					)
				);
				dispatch(scheduleSuccess());
			})
			.catch(error => {
				dispatch(scheduleError(connectionFilter(error)));
			});
	};
};

export const scheduleRemoveWorkHoursRecurringException = id => {
	return (dispatch, getState) => {
		dispatch(scheduleInitiate());

		const payload = {
			recurring_exception_id: id
		};

		axios
			.getInstance()
			.delete(workHoursRemoveRecurringExceptionRoute, {
				headers: {
					Authorization: getState().user.token
				},
				data: payload
			})
			.then(() => {
				dispatch(scheduleRemoveRecurringException(id));
				dispatch(
					internalNotificationsAdd(
						"You successfully deleted a recurring exception.",
						"success"
					)
				);
				dispatch(scheduleSuccess());
			})
			.catch(error => {
				dispatch(scheduleError(connectionFilter(error)));
			});
	};
};

export const scheduleRemoveWorkHoursException = dates => {
	return (dispatch, getState) => {
		dispatch(scheduleInitiate());

		const selectedDates = Object.keys(dates);
		const selectedIds = [];

		for (let i = 0; i < getState().schedule.exceptions.length; i++) {
			let element = getState().schedule.exceptions[i];
			if (selectedDates.indexOf(element.date) !== -1)
				selectedIds.push(element.id);
		}
		const payload = {
			ids: selectedIds
		};

		axios
			.getInstance()
			.delete(workHoursRemoveExceptionRoute, {
				headers: {
					Authorization: getState().user.token
				},
				data: payload
			})
			.then(() => {
				dispatch(scheduleRemoveException(selectedIds));
				dispatch(
					internalNotificationsAdd(
						"You successfully cleared exceptions for the selected date.",
						"success"
					)
				);
				dispatch(scheduleSuccess());
			})
			.catch(error => {
				dispatch(scheduleError(connectionFilter(error)));
			});
	};
};

export const scheduleRemoveWorkHoursHoliday = dates => {
	return (dispatch, getState) => {
		dispatch(scheduleInitiate());

		const selectedDates = Object.keys(dates);
		const selectedIds = [];
		for (let i = 0; i < getState().schedule.holidays.length; i++) {
			let element = getState().schedule.holidays[i];
			if (selectedDates.indexOf(element.date) !== -1)
				selectedIds.push(element.id);
		}
		const payload = {
			ids: selectedIds
		};

		axios
			.getInstance()
			.delete(workHoursRemoveHolidayRoute, {
				headers: {
					Authorization: getState().user.token
				},
				data: payload
			})
			.then(() => {
				dispatch(scheduleRemoveHoliday(selectedIds));
				dispatch(
					internalNotificationsAdd(
						"You successfully cleared holidays for the selected date.",
						"success"
					)
				);
				dispatch(scheduleSuccess());
			})
			.catch(error => {
				dispatch(scheduleError(connectionFilter(error)));
			});
	};
};
