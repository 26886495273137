import {
	INTERNAL_NOTIFICATIONS_ADD,
	INTERNAL_NOTIFICATIONS_REMOVE,
	INTERNAL_MAINTENANCE_ENABLE,
	INTERNAL_MAINTENANCE_DISABLE
} from "../actions.js";

const initialState = {
	notifications: [],
	maintenance: false
};

const reducer = (state = initialState, action) => {
	const array = state.notifications.map(element => ({ ...element }));
	switch (action.type) {
		case INTERNAL_NOTIFICATIONS_ADD: {
			array.push(action.payload.notificationItem);
			return {
				...state,
				notifications: array
			};
		}
		case INTERNAL_NOTIFICATIONS_REMOVE: {
			for (let notification in state.notifications)
				if (
					notification.id === action.payload.id &&
					notification.onDismiss
				)
					notification.onDismiss();
			return {
				...state,
				notifications: array.filter(
					element => element.id !== action.payload.id
				)
			};
		}
		case INTERNAL_MAINTENANCE_ENABLE: {
			return {
				...state,
				maintenance: true
			};
		}
		case INTERNAL_MAINTENANCE_DISABLE: {
			return {
				...state,
				maintenance: false
			};
		}
		default:
			return state;
	}
};

export default reducer;
