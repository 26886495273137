import {
	AUTH_TRY,
	AUTH_INITIATE,
	AUTH_SUCCESS,
	AUTH_ERROR,
	AUTH_LOGOUT,
	AUTH_REDIRECT
} from "../actions.js";
import axios from "../../utils/functions/axios-instance";

import {
	authRoute,
	authRegisterRoute,
	authMailRequestRoute,
	authMailDemoRoute,
	client_id,
	client_secret
} from "../../utils/constants/constants-api";
import {
	initOAuth,
	readOAuth,
	removeOAuth,
	initCredentials,
	readCredentials,
	removeCredentials
} from "../../utils/functions/oauth";
import { userClearCredentials, userUpdateToken, userDataFetch } from "./user";
import {
	internalNotificationsAdd,
	internalMaintenanceEnable
} from "./internal.js";
import { connectionFilter } from "../../utils/functions/filtering-functions";

export const authLogout = () => {
	removeOAuth();
	removeCredentials();
	return {
		type: AUTH_LOGOUT
	};
};

export const authRedirect = redirectPath => {
	return {
		type: AUTH_REDIRECT,
		payload: {
			redirect: redirectPath
		}
	};
};

const authInitiate = () => {
	return {
		type: AUTH_INITIATE
	};
};

const authSuccess = () => {
	return {
		type: AUTH_SUCCESS
	};
};

const authError = error => {
	return {
		type: AUTH_ERROR,
		payload: {
			error: error
		}
	};
};

const persistOAuth = refresh_token => {
	let local = readCredentials();
	if (local && local.refresh_token) initCredentials(refresh_token);
};

const authPersist = response => {
	return dispatch => {
		let { access_token, refresh_token, expires_in, token_type } = response;

		setTimeout(() => {
			dispatch(authRefreshToken());
		}, expires_in * 1000);

		initOAuth(access_token, refresh_token, expires_in, token_type);
		persistOAuth(refresh_token);
		const oauth = readOAuth();

		dispatch(
			userUpdateToken(
				oauth["token"],
				oauth["refresh_token"],
				oauth["expiration"]
			)
		);
		dispatch(userDataFetch());
		dispatch(authSuccess());
	};
};

const authRefreshToken = () => {
	return dispatch => {
		let local = readOAuth();

		if (local) {
			const payload = {
				refresh_token: local["refresh_token"],
				grant_type: "refresh_token",
				client_id,
				client_secret
			};

			axios
				.getInstance()
				.post(authRoute, payload)
				.then(response => {
					dispatch(authPersist(response.data));
				})
				.catch(error => {
					dispatch(userClearCredentials());
					dispatch(authError(connectionFilter(error)));
					removeCredentials();
				});
		}
	};
};

const authRefreshRememberedToken = refresh_token => {
	return dispatch => {
		dispatch(authInitiate());

		const payload = {
			grant_type: "refresh_token",
			refresh_token,
			client_id,
			client_secret
		};

		axios
			.getInstance()
			.post(authRoute, payload)
			.then(response => {
				dispatch(authPersist(response.data));
			})
			.catch(error => {
				dispatch(userClearCredentials());
				dispatch(authError(connectionFilter(error)));
				removeCredentials();
				if (error.response.status === 503)
					dispatch(internalMaintenanceEnable());
			});
	};
};

export const authenticatePersistedInit = () => {
	let local = readCredentials();
	return {
		type: AUTH_TRY,
		payload: {
			initial: local && local.refresh_token ? true : false
		}
	};
};

export const authenticatePersisted = () => {
	return dispatch => {
		let local = readCredentials();
		if (local && local.refresh_token)
			dispatch(authRefreshRememberedToken(local.refresh_token));
	};
};

export const authenticateLogin = (email, password, remember) => {
	return dispatch => {
		dispatch(authInitiate());

		const payload = {
			username: email,
			password: password,
			grant_type: "password",
			client_id,
			client_secret
		};

		axios
			.getInstance()
			.post(authRoute, payload)
			.then(response => {
				if (remember) initCredentials(response.data.refresh_token);
				dispatch(authPersist(response.data));
			})
			.catch(error => {
				dispatch(userClearCredentials());
				dispatch(authError(connectionFilter(error)));
				removeCredentials();
			});
	};
};

export const authenticateRegister = (
	email,
	password,
	name,
	surname,
	remember
) => {
	return dispatch => {
		dispatch(authInitiate());

		const payload = {
			username: email,
			password: password,
			name: name,
			surname: surname,
			grant_type: "password",
			client_id,
			client_secret
		};

		axios
			.getInstance()
			.post(authRegisterRoute, payload)
			.then(response => {
				if (remember) initCredentials(response.data.refresh_token);
				dispatch(authPersist(response.data));
			})
			.catch(error => {
				dispatch(userClearCredentials());
				dispatch(authError(connectionFilter(error)));
				removeCredentials();
			});
	};
};

export const authMailRequest = (email, body) => {
	return dispatch => {
		const payload = {
			email: email,
			body: body
		};

		axios
			.getInstance()
			.post(authMailRequestRoute, payload)
			.then(() => {
				dispatch(
					internalNotificationsAdd(
						"We are notified about your request. We will get in touch with you as soon as we can.",
						"success"
					)
				);
			})
			.catch(error => {
				internalNotificationsAdd(connectionFilter(error), "error");
			});
	};
};

export const authMailDemo = (email, body, name, company, phone) => {
	return dispatch => {
		const payload = {
			email: email,
			body: body,
			name: name,
			company: company,
			phone: phone
		};

		axios
			.getInstance()
			.post(authMailDemoRoute, payload)
			.then(() => {
				dispatch(
					internalNotificationsAdd(
						"We are notified about your demo request. We will get in touch with you as soon as we can.",
						"success"
					)
				);
			})
			.catch(error => {
				internalNotificationsAdd(connectionFilter(error), "error");
			});
	};
};
