import {
	USER_UPDATE_TOKEN,
	USER_CLEAR_CREDENTIALS,
	USER_FETCH_DATA,
	USER_ADD_NOTIFICATION_ID,
	USER_SUCCESS,
	USER_WAITING,
	USER_ERROR
} from "../actions.js";
import axios from "../../utils/functions/axios-instance";

import { authLogout } from "./auth";

import {
	userDataRoute,
	userAddNotificationIDRoute
} from "../../utils/constants/constants-api";
import { connectionFilter } from "../../utils/functions/filtering-functions";
import { ROLE_ADMIN } from "../../utils/constants/constants.js";

const userSuccess = () => {
	return {
		type: USER_SUCCESS
	};
};

const userInitiate = () => {
	return {
		type: USER_WAITING
	};
};

const userError = error => {
	return {
		type: USER_ERROR,
		payload: {
			error: error
		}
	};
};

const userData = data => {
	return {
		type: USER_FETCH_DATA,
		payload: {
			data: data
		}
	};
};

const userNotificationID = notificationID => {
	return {
		type: USER_ADD_NOTIFICATION_ID,
		payload: {
			notificationID: notificationID
		}
	};
};

export const userUpdateToken = (token, refreshToken, expiration) => {
	return {
		type: USER_UPDATE_TOKEN,
		payload: {
			token: token,
			refreshToken: refreshToken,
			expiration: expiration
		}
	};
};

export const userClearCredentials = () => {
	return {
		type: USER_CLEAR_CREDENTIALS
	};
};

export const userDataFetch = () => {
	return (dispatch, getState) => {
		dispatch(userInitiate());

		return axios
			.getInstance()
			.get(userDataRoute, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				if (response.data.role === ROLE_ADMIN) {
					dispatch(userData(response.data));
					dispatch(userSuccess());
				} else {
					dispatch(authLogout());
					dispatch(userSuccess());
				}
			})
			.catch(error => {
				dispatch(userError(connectionFilter(error)));
			});
	};
};

export const userNotificationIDsAdd = id => {
	return (dispatch, getState) => {
		dispatch(userInitiate());

		const payload = {
			notification_id: id
		};

		axios
			.getInstance()
			.post(userAddNotificationIDRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(() => {
				dispatch(userNotificationID(id));
				dispatch(userSuccess());
			})
			.catch(error => {
				dispatch(userError(connectionFilter(error)));
			});
	};
};
