import {
	PAYMENTS_FETCH,
	PAYMENTS_FETCH_PENDING,
	PAYMENTS_FETCH_LOCAL_PENDING,
	PAYMENTS_FETCH_MONTHLY_REPORT,
	PAYMENTS_EDIT,
	PAYMENTS_SELECT,
	PAYMENTS_SUCCESS,
	PAYMENTS_WAITING,
	PAYMENTS_ERROR,
	PAYMENTS_CHANGE_STATUS
} from "../actions.js";
import axios from "../../utils/functions/axios-instance";

import {
	paymentsGetRoute,
	paymentsPendingGetRoute,
	paymentsMonthlyReportGetRoute,
	paymentsUpdateRoute,
	paymentsLocalPendingGetRoute,
	paymentsChangeStatusRoute
} from "../../utils/constants/constants-api";

import { internalNotificationsAdd } from "./internal";
import { connectionFilter } from "../../utils/functions/filtering-functions";

const paymentsSuccess = () => {
	return {
		type: PAYMENTS_SUCCESS
	};
};

const paymentsInitiate = () => {
	return {
		type: PAYMENTS_WAITING
	};
};

const paymentsError = error => {
	return {
		type: PAYMENTS_ERROR,
		payload: {
			error: error
		}
	};
};

const paymentsFetch = payments => {
	return {
		type: PAYMENTS_FETCH,
		payload: {
			payments: payments
		}
	};
};

const paymentsFetchPending = payments => {
	return {
		type: PAYMENTS_FETCH_PENDING,
		payload: {
			payments: payments
		}
	};
};

const paymentsFetchLocalPending = payments => {
	return {
		type: PAYMENTS_FETCH_LOCAL_PENDING,
		payload: {
			payments: payments
		}
	};
};

const paymentsFetchMonthlyReport = monthlyReport => {
	return {
		type: PAYMENTS_FETCH_MONTHLY_REPORT,
		payload: {
			earnings: monthlyReport.earnings,
			customersByEarnings: monthlyReport.customersByEarnings
		}
	};
};

const paymentsEdit = paymentItem => {
	return {
		type: PAYMENTS_EDIT,
		payload: {
			paymentItem: paymentItem
		}
	};
};

export const paymentsSelectItem = id => {
	return {
		type: PAYMENTS_SELECT,
		payload: {
			id: id
		}
	};
};

export const paymentsChangeStatus = id => {
	return {
		type: PAYMENTS_CHANGE_STATUS,
		payload: {
			id: id
		}
	};
};

export const paymentsFetchAll = () => {
	return (dispatch, getState) => {
		dispatch(paymentsInitiate());

		axios
			.getInstance()
			.get(paymentsGetRoute, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(paymentsFetch(response.data));
				dispatch(paymentsSuccess());
			})
			.catch(error => {
				dispatch(paymentsError(connectionFilter(error)));
			});
	};
};

export const paymentsPendingFetchAll = () => {
	return (dispatch, getState) => {
		dispatch(paymentsInitiate());

		axios
			.getInstance()
			.get(paymentsPendingGetRoute, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(paymentsFetchPending(response.data));
				dispatch(paymentsSuccess());
			})
			.catch(error => {
				dispatch(paymentsError(connectionFilter(error)));
			});
	};
};

export const paymentsLocalPendingFetchAll = () => {
	return (dispatch, getState) => {
		dispatch(paymentsInitiate());

		axios
			.getInstance()
			.get(paymentsLocalPendingGetRoute, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(paymentsFetchLocalPending(response.data));
				dispatch(paymentsSuccess());
			})
			.catch(error => {
				dispatch(paymentsError(connectionFilter(error)));
			});
	};
};

export const paymentsMonthlyReportFetch = (year, month) => {
	return (dispatch, getState) => {
		dispatch(paymentsInitiate());

		axios
			.getInstance()
			.get(paymentsMonthlyReportGetRoute, {
				params: {
					year: year,
					month: ++month
				},
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(paymentsFetchMonthlyReport(response.data));
				dispatch(paymentsSuccess());
			})
			.catch(error => {
				dispatch(paymentsError(connectionFilter(error)));
			});
	};
};

export const paymentsChangeStatusAction = (id, reject = false) => {
	return (dispatch, getState) => {
		dispatch(paymentsInitiate());

		const payload = {
			payment_id: id,
			reject
		};

		axios
			.getInstance()
			.patch(paymentsChangeStatusRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(() => {
				dispatch(paymentsChangeStatus(id));
				dispatch(paymentsSuccess());
				dispatch(
					internalNotificationsAdd(
						reject
							? "Payment rejected. The user will have to initiate a new payment request."
							: "Payment fulfilled. You can see it in the Invoices overview tab now.",
						reject ? "danger" : "success"
					)
				);
			})
			.catch(error => {
				dispatch(paymentsError(connectionFilter(error)));
			});
	};
};

export const paymentsEditItem = id => {
	return (dispatch, getState) => {
		dispatch(paymentsInitiate());

		const payload = {
			id: id
		};

		axios
			.getInstance()
			.post(paymentsUpdateRoute, payload, {
				headers: {
					Authorization: getState().user.token
				}
			})
			.then(response => {
				dispatch(paymentsEdit({ ...response.data }));
				dispatch(paymentsSuccess());
			})
			.catch(error => {
				dispatch(paymentsError(connectionFilter(error)));
			});
	};
};
